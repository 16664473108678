// Export the route items
export default [
  {
    path: '/i/:slug',
    name: 'InviteForm',
    component: () => import(/* webpackChunkName: "crm-invite-form" */ '@/views/crm/InviteForm.vue'),
    meta: {
      title: "Influencer Invitation",
      template: "minimal",
      isGuestAllowed: true,
      isGuestRequired: false,
    }
  },
]
