<template>
  <div>
    <!-- Show the text -->
    <div class="d-flex justify-space-between">
      <div class="mb-6">
        <div class="text-h6 font-weight-bold">
          Influencer Invitations
        </div>

        <div class="text-subtitle-2">
          Invite influencers to join your campaign through a landing page/form
        </div>
      </div>

      <!-- Show the button -->
      <v-btn
        depressed
        color="primary"
        @click="showCreateForm"
      >
        <v-icon left>
          add
        </v-icon>

        Create Invitation
      </v-btn>
    </div>

    <!-- If there are no forms yet -->
    <div v-if="overview.inviteForms.length === 0">
      <v-alert
        depressed
        type="info"
      >
        You haven't created any invitations yet. Click the button above to create one.
      </v-alert>
    </div>
    <!-- Otherwise -->
    <div v-else>
      <!-- Show a grid -->
      <v-row>
        <v-col
          v-for="form in overview.inviteForms"
          :key="form.id"
          cols="12"
          md="6"
          lg="4"
        >
          <v-card flat>
            <v-card-title class="d-flex justify-space-between">
              <div>
                {{ form.name }}
              </div>

              <div
                v-if="overview.canUserWrite"
              >
                <v-menu
                  :disabled="isMakingRequest"
                  transition="slide-y-transition"
                  offset-y
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="primary" :disabled="isMakingRequest" v-bind="attrs" v-on="on">
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>

                  <!-- Show the menu options -->
                  <v-list width="160" dense>
                    <!-- Show the edit button -->
                    <v-list-item
                      @click="handleEditForm(form)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          Edit
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <!-- Show the delete button -->
                    <v-list-item
                      @click="handleDeleteForm(form)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          Delete
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-card-title>

            <v-card-subtitle>
              Total {{ nFormatter(form.influencers_count) }} submissions&nbsp;
              <span v-if="form.unapproved_influencers_count > 0">
                ({{ nFormatter(form.unapproved_influencers_count) }} unapproved)
              </span>
            </v-card-subtitle>

            <v-card-actions>
              <v-btn
                small
                depressed
                color="primary"
                @click="copyLinkToClipboard(form)"
              >
                <v-icon left>
                  link
                </v-icon>

                Copy URL
              </v-btn>

              <v-spacer />

              <v-btn
                small
                depressed
                color="primary"
                :disabled="form.influencers_count === 0"
                @click="handleViewForm(form)"
              >
                View

                <v-icon right>
                  fullscreen
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- Whether or not to show the form -->
    <invite-form-create
      v-model="shouldShowCreateForm"
      :model="overview.model"
      :module="module"
    />

    <!-- Show the influencers table dialog -->
    <invite-form-influencers
      v-model="shouldShowInfluencersDialog"
      :model="selectedForm"
      :key="influencersTableKey"
      @refresh="(e) => $emit('refresh', { ...selectedForm, ...e})"
    />
  </div>
</template>

<script>
// Import children components
const InviteFormCreate = () => import(/* webpackChunkName: "invite-form-create" */ "@/components/crm/invite-form/Create.vue")
const InviteFormInfluencers = () => import(/* webpackChunkName: "invite-form-influencers" */ "@/components/crm/invite-form/Influencers.vue")

// Export the SFC
export default {
  // Name of the component
  name: "InviteFormOverview",

  // Register the child components
  components: {
    InviteFormCreate,
    InviteFormInfluencers
  },

  // Accept incoming data from parent
  props: {
    overview: Object,
    module: String
  },

  // Define local variables
  data: () => ({
    shouldShowCreateForm: false,
    shouldShowDeleteForm: false,
    shouldShowEditForm: false,

    isMakingRequest: false,
    selectedForm: null,

    shouldShowInfluencersDialog: false,
    influencersTableKey: Symbol()
  }),

  // Define local method functions
  methods: {
    /**
     * Show the create form
     *
     * @return {void}
     */
    showCreateForm() {
      this.shouldShowCreateForm = true
    },

    /**
     * Handle the view form
     *
     * @param {Object} form
     * @return {void}
     */
    handleViewForm(form) {
      this.selectedForm = form
      this.influencersTableKey = Symbol()
      this.shouldShowInfluencersDialog = true
    },

    /**
     * Handle the edit form
     *
     * @param {Object} form
     * @return {void}
     */
    handleEditForm(form) {

    },

    /**
     * Handle the delete form
     *
     * @param {Object} form
     * @return {void}
     */
    handleDeleteForm(form) {

    },

    /**
     * Copy the shareable link to the clipboard
     *
     * @returns {void}
     */
    copyLinkToClipboard(form) {
      // Copy the link value
      navigator.clipboard.writeText(`${window.location.origin}${this.$router.resolve({ name: "InviteForm", params: { slug: form.slug } }).href}`)

      // Show a toast message
      this.$store.dispatch("toasts/add", { text: "URL copied to clipboard!" })
    },
  }
}
</script>
