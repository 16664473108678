// Export the state object
export default {
  // The default value for selected platforms
  selectedPlatforms: [],

  // InfluencerOutreach index
  items: [],

  // InfluencerOutreach Detailed Objects
  details: []
}
