import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

import host from "@/json/host.js"

import authRoutes from "./auth"
import teamRoutes from "./team"
import adminRoutes from "./admin"
import articleRoutes from "./articles"
import googleTrends from "./googleTrends"
import savedSearches from "./savedSearches"
import profileOverview from "./profileOverview"
import audienceOverlap from "./audienceOverlap"
import competitorCheck from "./competitorCheck"
import contentDiscovery from "./contentDiscovery"
import influencerInsight from "./influencerInsight"
import paidCollaborations from "./paidCollaborations"
import socialSentimentsRoutes from "./socialSentiments"
import campaignPlanningRoutes from "./campaignPlanning"

// CRM related routes
import mentionTrackingGroup from "./mentionTrackingGroup"
import influencerOutreach from "./influencerOutreach"
import influencerGroups from "./influencerGroups"
import campaignTracking from "./campaignTracking"
import mentionTracking from "./mentionTracking"
import inviteForm from "./inviteForm"

// Import the vuex store to load the user and validate routes
import store from "@/store/index.js"
import constants from "@/helpers/constants.js"

// Import tracking related functions
import { insertScripts, removeScripts } from "@/helpers/trackingCode.js"

window.STARTED_AT = Date.now()

const routes = [
  // Auth Pages
  ...authRoutes,

  ...adminRoutes,

  ...teamRoutes,
  ...googleTrends,
  ...articleRoutes,
  ...savedSearches,
  ...competitorCheck,
  ...profileOverview,
  ...audienceOverlap,
  ...contentDiscovery,
  ...influencerInsight,
  ...paidCollaborations,

  ...socialSentimentsRoutes,
  ...campaignPlanningRoutes,

  // CRM related routes
  ...mentionTrackingGroup,
  ...influencerOutreach,
  ...influencerGroups,
  ...campaignTracking,
  ...mentionTracking,
  ...inviteForm,

  // Landing Page
  {
    path: "/",
    redirect: "/login"
  },

  {
    path: "/faq",
    name: "FAQ",
    component: () => import(/* webpackChunkName: "faq" */ "@/views/FAQ.vue"),
    meta: {
      title: "Frequently Asked Questions",
      template: "empty",
      isGuestAllowed: true
    }
  },

  // The profile page
  {
    path: "/profile",
    name: "Profile",
    component: () => import(/* webpackChunkName: "profile-view" */ "@/views/Profile.vue"),
    meta: {
      template: "dashboard",
      title: "User Profile",
      appBarTitle: "User Profile"
    }
  },

  // Dashboard Pages
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import(/* webpackChunkName: "dashboard-view" */ "@/views/Dashboard.vue"),
    meta: {
      template: "dashboard",
      title: "Dashboard",
      appBarTitle: "Dashboard"
    }
  },
  // {
  //   path: "/paid-collaborations",
  //   name: "PaidCollaborations",
  //   component: () => import(/* webpackChunkName: "paid-collaborations" */ "@/views/PaidCollaborations.vue"),
  //   meta: {
  //     template: "dashboard",
  //     title: "Paid Collaborations",
  //     appBarTitle: "Paid Collaborations",
  //     serviceRequired: constants.model.user.allowedServices.paidCollaborations
  //   }
  // },
  // {
  //   path: "/paid-collaborations-comparison",
  //   name: "PaidCollaborationsComparison",
  //   component: () =>
  //     import(/* webpackChunkName: "paid-collaborations-comparison" */ "@/views/PaidCollaborationsComparison.vue"),
  //   meta: {
  //     template: "dashboard",
  //     title: "Paid Collaborations Comparison",
  //     appBarTitle: "Paid Collaborations Comparison",
  //     serviceRequired: constants.model.user.allowedServices.paidComparison
  //   }
  // },
  {
    path: "/influencer-discovery",
    name: "InfluencerDiscovery",
    component: () => import(/* webpackChunkName: "influencer-discovery" */ "@/views/InfluencerDiscovery.vue"),
    meta: {
      template: "dashboard",
      title: "Influencer Discovery",
      appBarTitle: "Influencer Discovery",
      serviceRequired: constants.model.user.allowedServices.influencerDiscovery
    }
  },
  {
    path: "/influencer-discovery/:uuid",
    name: "SavedInfluencerDiscoverySearch",
    component: () => import(/* webpackChunkName: "influencer-discovery" */ "@/views/InfluencerDiscovery.vue"),
    meta: {
      template: "dashboard",
      title: "Influencer Discovery",
      appBarTitle: "Influencer Discovery",
      serviceRequired: constants.model.user.allowedServices.influencerDiscovery
    }
  },

  {
    path: "/reports",
    name: "Reports",
    component: () => import(/* webpackChunkName: "reports" */ "@/views/Reports.vue"),
    meta: {
      title: "Reports",
      appBarTitle: "Reports",
      template: "dashboard"
    }
  },

  // For error pages
  {
    path: "/error",
    name: "Error",
    component: () => import(/* webpackChunkName: "error" */ "@/views/Error.vue"),
    meta: {
      title: "Error",
      template: "minimal"
    }
  },

  // For unknown paths
  {
    path: "*",
    name: "NotFound",
    component: () => import(/* webpackChunkName: "not-found" */ "@/views/NotFound.vue"),
    meta: {
      title: "Error 404 - Not Found",
      template: "minimal"
    }
  }
]

// no need to keep fetching profile data on each route change
// this will be updated from login page, if logged in
window.profileData = undefined

// Create a new vue-router instance
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

// Before each route change, call this function
router.beforeEach((to, from, next) => {
  // Set the window's document title
  window.document.title = to.meta.title ? `${to.meta.title} - ${host.name}` : host.name

  // If the to route requires tracking code
  if (to.meta.includeTrackingCode) {
    // Insert the tracking code
    insertScripts()
  }
  // Else if the from route required tracking code
  else if (from.meta.includeTrackingCode) {
    // Remove the tracking code
    removeScripts()
  }

  // If the route is asking to switch to guest if unauthenticated
  if (profileData === null && to.meta.switchTemplateToMinimalIfUnauthenticated) {
    // Update the meta value
    to.meta.template = "minimal"
  }

  // If the route isn't ready for guests, then check whether we're logged in, if not redirect to login
  if (to.meta.isGuestAllowed !== true && profileData === null) {
    // If we don't have the redirect set already
    if (!window.sessionStorage.getItem("redirectAfterLogin")) {
      // Set the path where we should come back after logging in
      window.sessionStorage.setItem("redirectAfterLogin", window.location.pathname + window.location.search)
    }

    // Redirect the user to login
    window.location = "/login"
  }

  // Else if the page requires the user to be a guest but user is logged in
  else if (to.meta.isGuestRequired && profileData !== null) {
    // Redirect to the dashboard
    next({ name: "Dashboard" })
  }

  // Check if the user is allowed to visit this particular page
  else if (to.meta.roleRequired && !to.meta.roleRequired.includes(profileData.role)) {
    // If no, then show a 404 message
    next({ name: "NotFound" })
  }

  // Finally run middleware function if present
  else if (typeof to.meta.middleware === "function" && to.meta.middleware(profileData) === false) {
    // If the middleware validation failed, show a 404 page
    next({ name: "NotFound" })
  }

  // In case there are multiple middlewares
  else if (to.meta.middlewares && Array.isArray(to.meta.middleware)) {
    // Go through each of the middlewares
    for (const middleware of to.meta.middleware) {
      // If it's not a function, skip it
      if (typeof middleware !== "function") continue

      // If a middleware check fails end the execution
      if (middleware(profileData) === false) {
        // Show a 404 page
        next({ name: "NotFound" })

        // Stop further execution of this loop
        break
      }
    }
  }

  // If a service is required for this route
  else if (to.meta.serviceRequired) {
    // Should we redirect or not
    let shouldContinue = false

    // If this is a guest view
    if (profileData === null && to.meta.isGuestAllowed === true) {
      // Let them continue
      shouldContinue = true
    }

    // In case the value is string
    if (typeof to.meta.serviceRequired === "string") {
      // Check if the user is allowed this service
      if (store.getters["auth/isServiceAllowed"](to.meta.serviceRequired)) {
        // If yes, then we can let the user continue
        shouldContinue = true
      }
    }

    // In case the value is an object (multiple conditions)
    if (typeof to.meta.serviceRequired === "object") {
      // If the condition asks for an "or" for multiple services
      if (to.meta.serviceRequired.type === "or") {
        // Set it to false
        shouldContinue = false

        // But if any of the condition is true, allow it to continue
        for (const service of to.meta.serviceRequired.items) {
          // If any of the service is not allowed to the user
          if (store.getters["auth/isServiceAllowed"](service) === true) {
            // Don't let them continue
            shouldContinue = true

            // Also, end the loop execution
            break
          }
        }
      }
    }

    // If the user is allowed to continue
    if (shouldContinue) {
      // Let the request succeed
      next()
    }
    // Otherwise
    else {
      // Show a 404 page
      next({ name: "NotFound" })
    }
  }

  // If all checks pass, continue
  else {
    // Let the request succeed
    next()
  }
})

export default router
