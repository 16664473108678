<template>
  <div>
    <!-- Show the main dialog -->
    <v-dialog
      :value="value"
      @input="$emit('input', $event)"
      :persistent="isMakingSubmitRequest || currentStep === 6"
      :loading="isMakingSubmitRequest"
      max-width="680"
      scrollable
    >
      <v-card>
        <v-card-title class="primary white--text pb-4 d-flex justify-space-between align-center">
          <div class="d-flex align-center">
            <v-btn
              v-if="currentStep > 1 && currentStep < 6"
              icon
              class="mr-3"
              color="white"
              @click="handleBackClick"
            >
              <v-icon>
                arrow_back
              </v-icon>
            </v-btn>

            {{ currentStep === 6 ? "Influencer Invitation" : "Create Influencer Invitation" }}
            &nbsp;
            <span v-if="currentStep > 1 && currentStep < 6">
              ({{ formData.skipLandingPage ? currentStep - 2 : currentStep - 1 }}/{{ formData.skipLandingPage ? 4 : 5 }})
            </span>
          </div>

          <v-btn
            v-if="currentStep > 1 && currentStep < 6"
            depressed
            color="white"
            class="primary--text"
            @click="validateAndNext"
            :loading="isMakingSubmitRequest || isValidatingSlug"
            :disabled="isMakingSubmitRequest || isValidatingSlug"
          >
            Continue

            <v-icon right>
              navigate_next
            </v-icon>
          </v-btn>

          <!-- On the last page -->
          <v-btn
            v-if="currentStep === 6"
            icon
            dark
            @click="$emit('close')"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text
          class="pt-6 invite-form"
          ref="formBody"
        >
          <!-- Ask whether to have a landing page -->
          <template v-if="currentStep === 1">
            <v-row>
              <v-col
                cols="12"
                md="6"
                @click="setSkipLandingPage(false)"
                class="pointer"
              >
                <div class="pa-6 accent rounded">
                  <v-icon size="48" color="secondary">
                    landscape
                  </v-icon>

                  <div class="text-h6 font-weight-bold mt-3 mb-2 text--text">
                    Landing Page
                  </div>

                  <div class="text-subtitle-2">
                    Create a landing page describing your campaign with images and videos which lead to the application form
                  </div>
                </div>
              </v-col>

              <v-col
                cols="12"
                md="6"
                @click="setSkipLandingPage(true)"
                class="pointer"
              >
                <div class="pa-6 accent rounded height-100">
                  <v-icon size="48" color="secondary">
                    draw
                  </v-icon>

                  <div class="text-h6 font-weight-bold mt-3 mb-2 text--text">
                    Application Form
                  </div>

                  <div class="text-subtitle-2">
                    Skip the landing page and go straight to the application form, making it quicker for influencers to apply
                  </div>
                </div>
              </v-col>
            </v-row>
          </template>

          <!-- To show the landing page inputs -->
          <template v-else-if="currentStep === 2">
            <!-- Show a text describing what these inputs are -->
            <div class="text-body-2 mb-4">
              Customize the heading and content to be shown on the landing page.
            </div>

            <!-- Show the input for page title -->
            <v-text-field
              v-model.trim="formData.landingPage.title"
              label="Landing Page Title"
              placeholder="Describe your campaign in a few words"
              :hide-details="!$v.formData.landingPage.title.$anyError"
              :error-messages="$v.formData.landingPage.title.$anyError ? ['Please enter a valid title'] : null"
              @blur="$v.formData.landingPage.title.$touch"
              class="mb-3"
              outlined
            />

            <!-- Show the textarea for description -->
            <Editor
              v-model="formData.landingPage.description"
              :editor-toolbar="editorToolbar"
              placeholder="Write a detailed description of your campaign using the rich text editor"
            />

            <!-- Show a divider -->
            <v-divider class="my-6"></v-divider>

            <!-- Show the continue button text input -->
            <div class="text-body-2">
              Customize the button text which would lead to the application form
            </div>

            <v-row class="mt-2">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model.trim="formData.landingPage.ctaText"
                  label="Button Text"
                  placeholder="Continue"
                  :hide-details="!$v.formData.landingPage.ctaText.$anyError"
                  :error-messages="$v.formData.landingPage.ctaText.$anyError ? ['Please enter a valid value under 20 characters'] : null"
                  @blur="$v.formData.landingPage.ctaText.$touch"
                  outlined
                  dense
                />
              </v-col>

              <!-- Show the text preview -->
              <v-col class="d-flex justify-center" cols="12" md="6">
                <v-btn
                  block
                  depressed
                  color="primary"
                  :disabled="$v.formData.landingPage.ctaText.$invalid"
                >
                  {{ formData.landingPage.ctaText || "Continue" }}
                </v-btn>
              </v-col>
            </v-row>

            <!-- Show a divider -->
            <v-divider class="my-6"></v-divider>

            <!-- Show the text -->
            <div class="text-body-2 mb-4">
              Upload images or link youtube videos to be shown on the landing page
            </div>

            <!-- Show a grid -->
            <v-row
              :key="gridKey"
            >
              <v-col
                v-for="(media, index) in formData.landingPage.media"
                :key="index"
                cols="12"
                md="6"
              >
                <!-- If it's blank -->
                <div
                  v-if="media.type === 'blank'"
                  class="media-preview-container d-flex justify-center align-center"
                  @click="showUploadDialog(index)"
                >
                  <v-icon size="48">
                    add
                  </v-icon>
                </div>

                <!-- Otherwise -->
                <div
                  v-else
                  class="relative"
                >
                  <!-- If it's an image -->
                  <v-img
                    v-if="media.type === 'image'"
                    :src="storageUrl(media.value.filepath)"
                    height="200"
                    cover
                  ></v-img>

                  <!-- If it's a youtube video -->
                  <youtube-embed
                    v-else-if="media.type === 'youtube'"
                    :url="media.value"
                    :height="200"
                    width="100%"
                  ></youtube-embed>

                  <!-- Show an option to remove this media -->
                  <div
                    class="absolute d-flex justify-end align-center pa-2 remove-media"
                    :style="{ bottom: media.type === 'youtube' ? '6px' : 0 }"
                  >
                    <v-icon
                      color="black"
                      class="pointer"
                      @click="handleRemoveMedia(index)"
                    >
                      close
                    </v-icon>
                  </div>
                </div>
              </v-col>
            </v-row>
          </template>

          <!-- To show the form inputs -->
          <template v-else-if="currentStep === 3">
            <!-- Show a text describing what these inputs are -->
            <div class="text-body-2 mb-4">
              Customize the heading and content to be shown on the form page.
            </div>

            <!-- Show the input for page title -->
            <v-text-field
              v-model.trim="formData.formPage.title"
              label="Form Heading"
              placeholder="Write the heading for the form page"
              :hide-details="!$v.formData.formPage.title.$anyError"
              :error-messages="$v.formData.formPage.title.$anyError ? ['Please enter a valid title'] : null"
              @blur="$v.formData.formPage.title.$touch"
              class="mb-3"
              outlined
            />

            <!-- Show the textarea for description -->
            <Editor
              v-model="formData.formPage.description"
              :editor-toolbar="editorToolbar"
              placeholder="Help explain the form to the influencers using the rich text editor"
            />

            <!-- Show the platform inputs -->
            <div class="text-body-2 mt-6">
              Select the platforms you want the influencers to have a presence on
            </div>

            <div class="d-flex items-center mt-2">
              <v-img
                v-for="platform in Object.keys(formData.formPage.platforms)"
                :key="platform"
                :class="{ 'opaque': !formData.formPage.platforms[platform] }"
                @click="() => (formData.formPage.platforms[platform] = !formData.formPage.platforms[platform])"
                :src="`/img/socials/${platform}.svg`"
                class="mr-3 pointer"
                max-width="40"
                height="40"
              ></v-img>
            </div>

            <!-- Show the contact inputs -->
            <div class="text-body-2 mt-6">
              Select the contact fields you want the influencers to provide
            </div>

            <div class="d-flex items-center mt-2">
              <v-icon
                v-for="contact in Object.keys(formData.formPage.contactFields)"
                :key="contact"
                :color="formData.formPage.contactFields[contact] ? 'primary' : 'black'"
                :class="{ 'opaque': !formData.formPage.contactFields[contact] }"
                @click="() => (formData.formPage.contactFields[contact] = !formData.formPage.contactFields[contact])"
                class="mr-3 pointer"
                size="40"
              >
                {{ contact === 'address' ? 'home' : contact }}
              </v-icon>
            </div>

            <!-- Show the price inputs -->
            <div class="text-body-2 mt-6">
              Select the contact fields you want the influencers to provide
            </div>

            <div class="d-flex items-center mt-2">
              <v-chip
                v-for="price in Object.keys(formData.formPage.priceFields)"
                :key="price"
                :color="formData.formPage.priceFields[price] ? 'primary' : null"
                @click="() => (formData.formPage.priceFields[price] = !formData.formPage.priceFields[price])"
                class="mr-3 pointer"
                size="40"
              >
                {{ capitalizeString(price) }}
              </v-chip>
            </div>

            <!-- Show a divider -->
            <v-divider class="my-6"></v-divider>

            <!-- Show the continue button text input -->
            <div class="text-body-2">
              Customize the button text which would lead to the thank you page
            </div>

            <v-row class="mt-2">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model.trim="formData.formPage.ctaText"
                  label="Button Text"
                  placeholder="Continue"
                  :hide-details="!$v.formData.formPage.ctaText.$anyError"
                  :error-messages="$v.formData.formPage.ctaText.$anyError ? ['Please enter a valid value under 20 characters'] : null"
                  @blur="$v.formData.formPage.ctaText.$touch"
                  outlined
                  dense
                />
              </v-col>

              <!-- Show the text preview -->
              <v-col class="d-flex justify-center" cols="12" md="6">
                <v-btn
                  block
                  depressed
                  color="primary"
                  :disabled="$v.formData.formPage.ctaText.$invalid"
                >
                  {{ formData.formPage.ctaText || "Submit" }}
                </v-btn>
              </v-col>
            </v-row>

            <!-- Show a divider -->
            <v-divider class="my-6"></v-divider>

            <!-- Show a text describing what these inputs are -->
            <div class="text-body-2 mb-4">
              Customize the heading and text to be shown on the thank you page.
            </div>

            <!-- Show the input for page title -->
            <v-text-field
              v-model.trim="formData.formPage.thankYouTitle"
              label="Page Title"
              placeholder="Write the heading for the thank you page"
              :hide-details="!$v.formData.formPage.thankYouTitle.$anyError"
              :error-messages="$v.formData.formPage.thankYouTitle.$anyError ? ['Please enter a valid title'] : null"
              @blur="$v.formData.formPage.thankYouTitle.$touch"
              class="mb-3"
              outlined
            />

            <!-- Show the textarea for description -->
            <Editor
              v-model="formData.formPage.thankYouDescription"
              :editor-toolbar="editorToolbar"
              placeholder="Write a thank you message for the influencer, to be shown after they submit"
            />
          </template>

          <!-- To show the inputs for theme and logo -->
          <template v-else-if="currentStep === 4">
            <!-- Show a text -->
            <div class="text-body-2 mb-4">
              <!-- Customize the invitation further with logo and colors -->
              Customize the invitation further with logo. If not provided, campaign logo will be used
            </div>

            <!-- Show the logo container -->
            <v-row>
              <v-col cols="12" md="4" lg="3">
                <!-- Show the campaign logo if available -->
                <div class="checkerboard-background min-width-100" style="height: 88px;">
                  <v-img
                    v-if="formData.logoPreview"
                    :src="formData.logoPreview"
                    height="88"
                    class="mb-4 checkerboard-background"
                    contain
                  />
                </div>
              </v-col>

              <v-col cols="12" md="8" lg="9">
                <v-card
                  outlined
                  class="d-flex justify-space-between align-center"
                >
                  <div>
                    <v-card-title>
                      Upload Logo (Optional)
                    </v-card-title>

                    <v-card-subtitle :class="{ 'red--text': $v.formData.logoInput.$anyError }">
                      Please select an image under 1MB
                    </v-card-subtitle>
                  </div>

                  <v-card-actions class="pr-4">
                    <!-- Show the upload logo field -->
                    <input
                      type="file"
                      name="invite-logo"
                      accept=".png, .jpeg, .jpg"
                      class="d-none"
                      ref="inviteLogoInput"
                      @change="handleLogoChange"
                    />

                    <v-btn
                      color="primary"
                      depressed
                      @click="$refs.inviteLogoInput && $refs.inviteLogoInput.click()"
                    >
                      <v-icon left>image</v-icon>
                      Upload
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>

            <!-- Show a divider -->
            <v-divider class="my-6"></v-divider>

            <!-- Show a text -->
            <div class="text-body-2 mb-4">
              Customize the colors to match your branding
            </div>

            <v-row>
              <v-col cols="12" md="4">
                <color-picker
                  v-model="formData.style.colors.background"
                  label="Background Color"
                />
              </v-col>

              <v-col cols="12" md="4">
                <color-picker
                  v-model="formData.style.colors.title"
                  label="Title Color"
                />
              </v-col>

              <v-col cols="12" md="4">
                <color-picker
                  v-model="formData.style.colors.text"
                  label="Text Color"
                />
              </v-col>
            </v-row>

            <div
              class="pa-3 mt-4 mb-6"
              style="border: 1px solid #E0E0E0; border-radius: 4px;"
              :style="{ backgroundColor: formData.style.colors.background }"
            >
              <div
                class="text-h5 font-weight-bold"
                :style="{ color: formData.style.colors.title }"
              >
                This is a title text
              </div>

              <div
                class="text-body-2"
                :style="{ color: formData.style.colors.text }"
              >
                This is a paragraph text
              </div>
            </div>

            <v-row>
              <v-col cols="12" md="4">
                <color-picker
                  v-model="formData.style.colors.buttonBackground"
                  label="Button BG Color"
                />
              </v-col>

              <v-col cols="12" md="4">
                <color-picker
                  v-model="formData.style.colors.buttonText"
                  label="Button Text Color"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-btn
                  block
                  depressed
                  :style="{ backgroundColor: formData.style.colors.buttonBackground, color: formData.style.colors.buttonText }"
                >
                  PREVIEW
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <!-- To show the inputs for slug and name -->
          <template v-else-if="currentStep === 5">
            <!-- Show a text -->
            <div class="text-body-2 mb-4">
              Only you can see this name, it'll help you if you have multiple invitations in one campaign
            </div>

            <!-- Show the input for invitation name -->
            <v-text-field
              v-model.trim="formData.name"
              label="Invitation Name"
              placeholder="Enter a name for the invitation"
              :hide-details="!$v.formData.name.$anyError"
              :error-messages="$v.formData.name.$anyError ? ['Please enter a valid name'] : null"
              @blur="$v.formData.name.$touch"
              class="mb-3"
              outlined
            />

            <!-- Show a divider -->
            <v-divider class="my-6"></v-divider>

            <!-- Show a text -->
            <div class="text-body-2 mb-4">
              Customize the URL for this invitation, you can't change it later.
            </div>

            <!-- Show the input for invitation slug -->
            <v-text-field
              v-model="formData.slug"
              label="Invitation URL"
              placeholder="Enter a unique slug for the invitation"
              :prefix="computedSlugPrefix"
              :error-messages="computedSlugErrorMessages"
              @blur="slugifyInput(); $v.formData.slug.$touch();"
              outlined
            />

            <!-- Show a divider -->
            <v-divider class="my-6"></v-divider>

            <!-- Show the inputs for notification checkboxes -->
            <v-checkbox
              v-model="formData.notifyOnSubmission"
              label="Notify me when an influencer submits the form"
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-if="formData.formPage.contactFields.email"
              v-model="formData.notifyOnApproval"
              label="Notify the influencer when they are approved"
              hide-details
              class="mb-3"
            ></v-checkbox>
          </template>

          <!-- To show the success page -->
          <template v-else-if="currentStep === 6">
            <!-- Show an alert -->
            <v-alert
              type="success"
              icon="check_circle"
            >
              Your invitation has been created, start sharing it with influencers.
            </v-alert>

            <v-text-field
              :value="shareableURL"
              label="Click to copy invitation URL"
              @click="copyLinkToClipboard"
              color="primary"
              class="mt-6"
              hide-details
              readonly
              outlined
              dense
            ></v-text-field>

            <div class="d-flex justify-end mt-6">
              <v-btn
                depressed
                color="primary"
                @click="$emit('close')"
              >
                Close
              </v-btn>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Show the dialog for uploads -->
    <v-dialog
      v-model="shouldShowUploadDialog"
      :loading="isMakingUploadRequest"
      :persistent="isMakingUploadRequest"
      max-width="650"
    >
      <v-card v-if="selectedMediaIndex !== null">
        <v-card-title class="primary white--text d-flex align-center">
          <v-icon dark>
            cloud_upload
          </v-icon>

          <div class="ml-4">
            Upload Media or Link YouTube Video
          </div>
        </v-card-title>

        <v-card-text class="pt-6">
          <!-- Ask for youtube URL -->
          <v-text-field
            v-model.trim="youtubeInput"
            placeholder="https://www.youtube.com/watch?v=..."
            hide-details
            label="YouTube URL"
            outlined
          />

          <!-- Show a divider with text -->
          <v-divider class="my-4"></v-divider>

          <!-- Show the file upload option -->
          <v-row>
            <v-col cols="12" md="4">
              <!-- Show the logo if available -->
              <div
                class="checkerboard-background min-width-100"
                style="height: 88px;"
              >
                <v-img
                  v-if="filePreview"
                  :src="filePreview"
                  height="88"
                  class="mb-4 checkerboard-background"
                  contain
                />
              </div>
            </v-col>

            <v-col cols="12" md="8">
              <v-card
                outlined
                class="d-flex justify-space-between align-center"
              >
                <div>
                  <v-card-title>
                    Upload Image
                  </v-card-title>

                  <v-card-subtitle
                    :class="{ 'red--text': $v.fileInput.$anyError }"
                    class="text-caption"
                  >
                    Please select an image under 1MB
                  </v-card-subtitle>
                </div>

                <v-card-actions class="pr-4">
                  <!-- Show the upload logo field -->
                  <input
                    type="file"
                    name="logo"
                    accept=".png, .jpeg, .jpg"
                    class="d-none"
                    ref="imageInput"
                    @change="handleImageChange"
                  />

                  <v-btn
                    color="primary"
                    depressed
                    @click="$refs.imageInput && $refs.imageInput.click()"
                    :loading="isMakingUploadRequest"
                    :disabled="isMakingUploadRequest"
                  >
                    <v-icon left>image</v-icon>
                    Upload
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="shouldShowUploadDialog = false"
          >
            Close
          </v-btn>

          <v-btn
            depressed
            color="primary"
            @click="handleMediaSubmit"
            :loading="isMakingUploadRequest"
            :disabled="isMakingUploadRequest"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import helper functions
import { required, minLength, maxLength } from "vuelidate/lib/validators"
import slugify from "slugify"

// Import the components
import { VueEditor as Editor } from 'vue2-editor'
const ColorPicker = () => import(/* webpackChunkName: "color-picker" */ '@/blocks/common/form/ColorPicker')
const YoutubeEmbed = () => import(/* webpackChunkName: "youtube-embed" */ '@/blocks/common/YoutubeEmbed.vue')

// @TODO: Define default custom question field
const defaultCustomQuestion = {}

// Export the SFC
export default {
  // Name of the component
  name: "FormComponent",

  // Register the components
  components: {
    Editor,
    ColorPicker,
    YoutubeEmbed
  },

  // Accept incoming data from parent
  props: {
    value: Boolean,
    model: Object,
    module: String
  },

  // Define local variables
  data: () => ({
    isMakingSubmitRequest: false,
    currentStep: 1,

    isValidatingSlug: false,
    shouldMarkSlugAsInvalid: false,

    formData: {
      name: "",
      slug: "",

      logoInput: null,
      logoPreview: null,

      skipLandingPage: false,
      landingPage: {
        title: "",
        description: "",
        ctaText: "Continue",
        media: Array(4).fill({
          type: "blank",
          value: null
        })
      },

      formPage: {
        title: "",
        description: "",
        platforms: {
          instagram: false,
          youtube: false,
          tiktok: false
        },
        contactFields: {
          phone: false,
          email: false,
          address: false
        },
        priceFields: {
          photo: false,
          video: false,
          story: false,
          carousel: false
        },
        customQuestions: [],
        ctaText: "Submit",

        thankYouTitle: "",
        thankYouDescription: "",
      },

      notifyOnSubmission: false,
      notifyOnApproval: false,

      style: {
        colors: {
          title: "#000000",
          text: "#000000",
          background: "#FFFFFF",
          buttonText: "#FFFFFF",
          buttonBackground: null
        }
      }
    },

    editorToolbar: [
      [{ 'font': [] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['link', 'blockquote', { 'align': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'color': [] }, { 'background': [] }],
    ],

    selectedMediaIndex: null,
    shouldShowUploadDialog: false,
    youtubeInput: null,
    fileInput: null,
    filePreview: null,
    isMakingUploadRequest: false,

    gridKey: Symbol()
  }),

  // Define computable properties
  computed: {
    /**
     * Compute the hint for the slug
     *
     * @return {String}
     */
    computedSlugPrefix() {
      return `${window.location.origin}/i/`
    },

    /**
     * Compute the error messages for the slug
     *
     * @return {Array}
     */
    computedSlugErrorMessages() {
      if (this.$v.formData.slug.$anyError) {
        return ['Please enter a value between 3-100 characters']
      }

      if (this.shouldMarkSlugAsInvalid) {
        return ['This slug is already taken']
      }

      return null
    },

    /**
     * Compute the shareable URL
     *
     * @return {String}
     */
    shareableURL() {
      return `${this.computedSlugPrefix}${this.formData.slug}`
    }
  },

  // Define validations
  validations() {
    // Initiate the object
    const object = {
      formData: {
        name: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100)
        },

        slug: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100)
        },

        formPage: {
          title: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(100)
          },

          ctaText: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(20)
          },

          thankYouTitle: {
            maxLength: maxLength(100)
          }
        },

        logoInput: {
          maxSize: (value) => {
            return value ? value.size <= 1_000_000 : true // Maximum 1MB in size
          },
          mimeType: (value) => {
            return value ? ["image/png", "image/jpeg"].includes(value.type) : true
          }
        },
      },

      fileInput: {
        maxSize: (value) => {
          return value ? value.size <= 1_000_000 : true // max 1mb in size
        },
        mimeType: (value) => {
          return value ? ["image/png", "image/jpeg"].includes(value.type) : true
        }
      }
    }

    // If we're not skipping the landing page
    if (this.formData.skipLandingPage === false) {
      // Add the validations for the landing page
      object.formData.landingPage = {
        title: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100)
        },
        description: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(5000)
        },
        ctaText: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(20)
        }
      }
    }

    // Return the object
    return object
  },

  // Define local method functions
  methods: {
    /**
     * Implement the slugify input
     *
     * @returns {void}
     */
    slugifyInput() {
      this.formData.slug = slugify(this.formData.slug, {
        lower: true,
        strict: true
      })
    },

    /**
     * Handle the back click
     *
     * @return {void}
     */
    handleBackClick() {
      // Check the current step is set to the form screen
      if (this.currentStep === 3) {
        // If we have to skip the landing page
        this.currentStep = this.formData.skipLandingPage ? 1 : 2
      }
      // Otherwise
      else {
        // Decrement the current step
        this.currentStep--
      }
    },

    /**
     * Set the skip landing page value
     *
     * @param {Boolean} value
     */
    setSkipLandingPage(value) {
      // Update the value
      this.formData.skipLandingPage = value

      // Decide which step to show next
      if (value === true) {
        // If we're skipping
        this.currentStep = 3
      }
      // Otherwise
      else {
        // If we're not skipping
        this.currentStep = 2
      }
    },

    /**
     * Validate the current step and move to the next
     *
     * @return {void}
     */
    async validateAndNext() {
      // Switch between the current step
      switch (this.currentStep) {
        // Landing Page
        case 2:
          // Run the validations
          await this.$v.formData.landingPage.$touch()

          // If the form is invalid
          if (this.$v.formData.landingPage.$invalid) {
            // Show an error message
            this.$store.dispatch("toasts/add", { text: "Please fill in the required fields" })
          }
          // Otherwise
          else {
            // Increment the step
            this.currentStep++

            // Scroll to the top
            this.$refs.formBody.scrollTop = 0
          }

          break

        // Form Page
        case 3:
          // Run the validations
          await this.$v.formData.formPage.$touch()

          // If the form is invalid
          if (this.$v.formData.formPage.$invalid) {
            // Show an error message
            this.$store.dispatch("toasts/add", { text: "Please fill in the required fields" })
          }
          // Otherwise
          else {
            // If none of the platform is selected
            if (Object.values(this.formData.formPage.platforms).filter(v => v).length === 0) {
              // Show a toast message
              this.$store.dispatch("toasts/add", { text: "Please select a social media platform" })
            }
            // Otherwise
            else {
              // Increment the step
              this.currentStep++
            }
          }

          break

        // Theme and Logo
        case 4:
          // Run the validations
          await this.$v.formData.logoInput.$touch()

          // If the form is invalid
          if (this.$v.formData.logoInput.$invalid) {
            // Show an error message
            this.$store.dispatch("toasts/add", { text: "Please select a valid image" })
          }
          // Otherwise
          else {
            // Increment the step
            this.currentStep++
          }

          break

        // Slug and Name
        case 5:
          // If we're already validating slug
          if (this.isValidatingSlug) {
            // Show an error message
            this.$store.dispatch("toasts/add", { text: "Please wait while we validate the URL" })
          }

          // Slugify the input
          this.slugifyInput()

          // Run the validations
          await this.$v.formData.name.$touch()
          await this.$v.formData.slug.$touch()

          // If the form is invalid
          if (this.$v.formData.name.$invalid || this.$v.formData.slug.$invalid) {
            // Show an error message
            this.$store.dispatch("toasts/add", { text: "Please fill in the required fields" })
          }
          // Otherwise
          else {
            // Check if the slug is valid
            this.isValidatingSlug = true

            try {
              await axios({
                method: "POST",
                url: "/api/check/invite-forms",
                data: {
                  slug: this.formData.slug
                }
              })

              // Call the submission function
              this.submitForm()
            }
            catch (error) {
              // Show an error message
              this.shouldMarkSlugAsInvalid = true

              // Show a toast message
              this.$store.dispatch("toasts/add", { text: "This URL is already taken" })
            }
            finally {
              this.isValidatingSlug = false
            }
          }
      }
    },

    /**
     * Handle final form submission
     *
     * @returns {void}
     */
    async submitForm() {
      // Mark the form as submitting
      this.isMakingSubmitRequest = true

      // Create the map for module and class
      const moduleMap = {
        campaignTracking: "App\\Models\\CampaignTracking",
        influencerGroup: "App\\Models\\InfluencerGroup"
      }

      // Prepare the data
      const formData = new FormData()

      formData.append("name", this.formData.name)
      formData.append("slug", this.formData.slug)

      // @TODO
      formData.append("template", "default")

      formData.append("owner_id", this.model.id)
      formData.append("owner_type", moduleMap[this.module])

      formData.append("skip_intro", this.formData.skipLandingPage)

      // If we're not skipping the landing page
      if (this.formData.skipLandingPage === false) {
        formData.append("intro_title", this.formData.landingPage.title)
        formData.append("intro_description", this.formData.landingPage.description)
        formData.append("intro_cta_text", this.formData.landingPage.ctaText)

        formData.append("intro_media", JSON.stringify(
          this.formData.landingPage.media
            .filter((item) => item.type !== "blank")
            .map((item) => ({
              type: item.type,
              value: item.type === "image" ? String(item.value.id) : item.value
            })))
        )
      }

      formData.append("form_title", this.formData.formPage.title)
      formData.append("form_description", this.formData.formPage.description)

      formData.append("platform_fields", JSON.stringify(
        Object.entries(this.formData.formPage.platforms)
          .filter(([key, value]) => value)
          .map(([key]) => key)
      ))

      formData.append("contact_fields", JSON.stringify(
        Object.entries(this.formData.formPage.contactFields)
          .filter(([key, value]) => value)
          .map(([key]) => key)
      ))

      formData.append("price_fields", JSON.stringify(
        Object.entries(this.formData.formPage.priceFields)
          .filter(([key, value]) => value)
          .map(([key]) => key)
      ))

      // @TODO
      formData.append("custom_questions", JSON.stringify([]))

      formData.append("form_cta_text", this.formData.formPage.ctaText)

      formData.append("thank_you_title", this.formData.formPage.thankYouTitle)
      formData.append("thank_you_description", this.formData.formPage.thankYouDescription)

      formData.append("style", JSON.stringify({
        title_color: this.formData.style.colors.title,
        text_color: this.formData.style.colors.text,
        background_color: this.formData.style.colors.background,
        button_text_color: this.formData.style.colors.buttonText,
        button_background_color: this.formData.style.colors.buttonBackground
      }))
      formData.append("notify_on_submission", this.formData.notifyOnSubmission)
      formData.append("notify_on_approval", this.formData.notifyOnApproval)

      // Compute what to do with the logo
      formData.append("logo_action", this.formData.logoInput === null ? "ignore" : "update")

      // If we don't have a logo to begin with, don't send anything
      if (this.formData.logoInput !== null) {
        formData.append("logo_file", this.formData.logoInput)
      }

      // Try making the request
      try {
        // Make the request
        const response = await axios({
          method: "POST",
          url: "/api/invite-forms",
          data: formData
        })

        // Push this to the module
        this.$store.dispatch(`${this.module}/addInviteForm`, response.data)

        // Show the final page
        this.currentStep++
      }
      // Catch any errors
      catch (error) {
        // Show an error message
        this.$store.dispatch("toasts/add", { text: "An error occurred while submitting the form" })
      }
      // Nonetheless
      finally {
        // Mark the form as not submitting
        this.isMakingSubmitRequest = false
      }
    },

    /**
     * Show the upload dialog for the selected option
     *
     * @param {Number} index
     */
    showUploadDialog(index) {
      // Reset the values
      this.youtubeInput = null
      this.fileInput = null
      this.filePreview = null
      this.$v.fileInput.$reset()

      // Update the selected media index
      this.selectedMediaIndex = index

      // Show the upload dialog
      this.shouldShowUploadDialog = true
    },

    /**
     * Handle the image change event
     *
     * @param {Event} event
     */
    async handleImageChange(event) {
      // Check if the event has any files in it
      if (!event.target.files || !event.target.files.length) {
        // Show an error message
        this.$store.dispatch("toasts/add", { text: "No file selected" })
      }

      // Get the file object
      this.fileInput = event.target.files[0] || null

      // Run the validations
      await this.$v.fileInput.$touch()

      // If an image is selected
      if (this.fileInput && this.fileInput instanceof File) {
        this.filePreview = URL.createObjectURL(this.fileInput)
      }
      // If an image has been removed, fallback to default
      else {
        this.filePreview = null
        this.fileInput = null
      }
    },

    /**
     * Handle the media submit event
     *
     * @returns {void}
     */
    async handleMediaSubmit() {
      // Show the loading state
      this.isMakingUploadRequest = true

      // If we have a file input
      if (this.fileInput) {
        // Run the validations
        await this.$v.fileInput.$touch()

        // If the file input is invalid
        if (this.$v.fileInput.$invalid) {
          // Show an error message
          this.$store.dispatch("toasts/add", { text: "Please select a valid image" })
        }
        // Otherwise
        else {
          // Initiate a new form data
          const formData = new FormData()
          formData.append("file", this.fileInput)

          // Upload the file
          const response = await axios({
            url: "/api/public-uploads",
            method: "POST",
            data: formData
          })

          // Update the value
          this.formData.landingPage.media[this.selectedMediaIndex] = {
            type: "image",
            value: response.data
          }

          // Refresh the key
          this.gridKey = Symbol()
        }
      }

      // If we have a youtube input
      if (this.youtubeInput) {
        // Get the code from the URL
        const code = regex.youtube.video.reduce((acc, pattern) => {
          if (acc) return acc

          const match = this.youtubeInput.match(pattern)
          return match ? match[1] : null
        }, null)

        // If we don't have a code
        if (!code) {
          // Show an error message
          this.$store.dispatch("toasts/add", { text: "Please enter a valid YouTube URL" })
        }
        // Otherwise
        else {
          // Update the value
          this.formData.landingPage.media[this.selectedMediaIndex] = {
            type: "youtube",
            value: `https://www.youtube.com/watch?v=${code}`
          }

          // Refresh the key
          this.gridKey = Symbol()
        }
      }

      // Close the dialog
      this.shouldShowUploadDialog = false
      this.isMakingUploadRequest = false

      // Reset the state
      this.youtubeInput = null
      this.fileInput = null
      this.filePreview = null
      this.selectedMediaIndex = null
      this.$v.fileInput.$reset()
    },

    /**
     * Handle the remove media event
     *
     * @param {Number} index
     */
    handleRemoveMedia(index) {
      this.formData.landingPage.media[index] = { type: 'blank', value: null }
      this.gridKey = Symbol()
    },

    /**
     * Handle the event when the image changes in input
     *
     * @param {Object} event
     * @returns {void}
     */
    async handleLogoChange(event) {
      // Check if the event has any files in it
      if (!event.target.files || !event.target.files.length) {
        // Show an error message
        this.$store.dispatch("toasts/add", { text: "No file selected" })
      }

      // Get the file object
      this.formData.logoInput = event.target.files[0] || null

      // Run the validations
      await this.$v.formData.logoInput.$touch()

      // If an image is selected
      if (this.formData.logoInput && this.formData.logoInput instanceof File) {
        this.formData.logoPreview = URL.createObjectURL(this.formData.logoInput)
      }
    },

    /**
     * Copy the shareable link to the clipboard
     *
     * @returns {void}
     */
     copyLinkToClipboard() {
      // Copy the link value
      navigator.clipboard.writeText(this.shareableURL)

      // Show a toast message
      this.$store.dispatch("toasts/add", { text: "URL copied to clipboard!" })
    },
  },

  /**
   * As soon as the component is created
   *
   * @returns {void}
   */
  created() {
    this.formData.style.colors.buttonBackground = this.$vuetify.theme.themes.light.buttonPrimary
  }
}
</script>

<style lang="stylus">
.invite-form
  .ql-container.ql-snow
    border-radius 0px 0px 4px 4px !important
    border-color rgba(0, 0, 0, 0.38) !important

  .ql-toolbar.ql-snow
    border-radius 4px 4px 0px 0px !important
    border-color rgba(0, 0, 0, 0.38) !important

  .ql-editor.ql-blank::before
    font-style normal !important
    color rgba(0, 0, 0, 0.38) !important

  .media-preview-container
    width 100%
    height 200px
    background-color rgba(0, 0, 0, 0.04)

  .remove-media
    width 100%
    bottom 0
    right 0
    background rgba(255, 255, 255, 0.9)

  .opaque
    opacity 0.2
</style>
