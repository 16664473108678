<template>
  <div>
    <!-- The header buttons -->
    <div class="d-flex justify-space-between">
      <!-- Show the go back button -->
      <v-btn
        text
        color="primary"
        :disabled="isMakingRequest"
        @click="$router.push({ name: 'InfluencerOutreachIndex' })"
      >
        <v-icon left>
          arrow_back
        </v-icon>

        Campaigns
      </v-btn>

      <div class="d-flex">
        <!-- Show the go back button -->
        <v-btn
          text
          class="mr-3"
          color="primary"
          :disabled="isMakingRequest"
          @click="$router.push({ name: 'InfluencerOutreachEdit' })"
        >
          <v-icon left>
            edit
          </v-icon>

          Edit
        </v-btn>

        <!-- Show the go back button -->
        <v-btn
          text
          color="primary"
          :disabled="isMakingRequest"
          @click="$router.push({ name: 'InfluencerOutreachAnalytics' })"
        >
          <v-icon left>
            analytics
          </v-icon>

          Analytics
        </v-btn>
      </div>
    </div>

    <!-- Show the buttons here -->
    <div class="mt-8 d-flex justify-space-between">
      <!-- Show the input for status here -->
      <div>
        <!-- Select Input for Filter -->
        <v-select
          dense
          outlined
          class="contain-select-width"
          label="Campaign Status"
          hide-details
          :disabled="isMakingRequest"
          :loading="isMakingUpdateRequest"
          :items="statusOptions"
          :value="data.model.status"
          @change="handleStatusChange"
        ></v-select>
      </div>

      <div class="d-flex">
        <!-- Show the button to remove influencers -->
        <v-btn
          v-if="selectedItems.length > 0"
          depressed
          class="mr-3"
          color="primary"
          :loading="isMakingDeleteRequest"
          :disabled="isMakingRequest"
          @click="shouldShowDeleteConfirmationDialog = true"
        >
          <v-icon left>
            delete
          </v-icon>

          Remove {{ selectedItems.length > 1 ? "Influencers" : "Influencer" }}
        </v-btn>

        <!-- Show the button to add influencer -->
        <v-btn
          depressed
          color="primary"
          :disabled="isMakingRequest"
          @click="$emit('triggerAddInfluencer')"
        >
          <v-icon left>
            add
          </v-icon>

          Add Influencer
        </v-btn>
      </div>
    </div>

    <!-- Show the table view -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableHeaders"
      :items="tableItems"
      :loading="isMakingDeleteRequest"
      class="elevation-1 mt-6"
      item-key="id"
      selectable-key="id"
      show-select
    >
      <!-- Modify the platform column -->
      <template v-slot:item.platform="{ item }">
        <div>
          <v-img
            :src="`/img/socials/${item.platform}.svg`"
            max-width="32"
            height="32"
          ></v-img>
        </div>
      </template>

      <!-- Modify the influencer column -->
      <template v-slot:item.username="{ item }">
        <profile-chip
          :platform="item.platform"
          :data="item.preview || { picture: null, username: item.username, fullname: item.username }"
        />
      </template>

      <!-- Modify the status column -->
      <template v-slot:item.status="{ item }">
        <status-chip
          :status="item.status"
        />
      </template>

      <!-- Modify the actions column -->
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-end">
          <v-btn
            small
            depressed
            color="primary"
            @click="$router.push({ name: 'InfluencerOutreachInfluencer', params: { uuid: $route.params.uuid, influencerId: item.id } })"
          >
            View

            <v-icon right>
              fullscreen
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <!-- Show a confirmation dialog -->
    <v-dialog
      v-model="shouldShowDeleteConfirmationDialog"
      max-width="500"
      :persistent="isMakingDeleteRequest"
    >
      <v-card>
        <v-card-title>
          {{ isMakingDeleteRequest ? "Removing influencers..." : "This cannot be undone!" }}
        </v-card-title>

        <v-card-text>
          {{ isMakingDeleteRequest ? "Please wait while we delete selected influencers from this campaign" : "If you click continue, we'll remove all posts, tasks, reports about these influencers from the database." }}
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingDeleteRequest"
            @click="shouldShowDeleteConfirmationDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            :disabled="isMakingDeleteRequest"
            :loading="isMakingDeleteRequest"
            @click="handleDeleteRequest"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import children components
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */"@/blocks/common/ProfileChip")
const StatusChip = () => import(/* webpackChunkName: "status-chip" */ "@/blocks/common/StatusChip")

// Export the SFC
export default {
  // Name of the SFC
  name: "InfluencerOutreachDetail",

  // Register children components
  components: {
    ProfileChip,
    StatusChip
  },

  // Accept incoming data from parent
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  // Define local data variables
  data: () => ({
    // Get all the selected items here
    selectedItems: [],
    // Whether or not is the app making a network request
    isMakingRequest: false,
    // Whether or not is the app making an update request
    isMakingUpdateRequest: false,
    // Whether or not is the app making a delete request
    isMakingDeleteRequest: false,
    // Whether or not to show the confirmation delete dialog
    shouldShowDeleteConfirmationDialog: false,

    // Get the status option values
    statusOptions: [
      {
        text: "Pending",
        value: "pending",
      },
      {
        text: "In Progress",
        value: "in-progress",
      },
      {
        text: "Cancelled",
        value: "cancelled",
      },
      {
        text: "Completed",
        value: "completed",
      },
    ]
  }),

  // Computable readonly data variables
  computed: {
    /**
     * Get all the table header items
     *
     * @returns {Array}
     */
    tableHeaders() {
      return [
        {
          text: "",
          value: "platform",
          sortable: false,
        },
        {
          text: "Influencer",
          value: "username",
          sortable: true,
          sort: (a, b) => a.localeCompare(b)
        },
        {
          text: "No. of Tasks",
          value: "tasks_count",
          sortable: true
        },
        {
          text: "No. of Posts",
          value: "posts_count",
          sortable: true
        },
        {
          text: "Total Cost",
          value: "total_cost",
          sortable: true
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
          align: "right"
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          align: "right"
        }
      ]
    },

    /**
     * Get all the table row items
     *
     * @returns {Array}
     */
    tableItems() {
      // Map through each influencer item
      return this.data.influencers.map((influencer) => {
        // Compute an object for table row data
        return {
          id: influencer.id,
          platform: influencer.platform,
          username: influencer.username,
          preview: influencer.preview,
          status: influencer.status,

          tasks_count: this.data.tasks.filter((search) => search.campaign_report_influencer_id === influencer.id).length,
          posts_count: this.data.posts.filter((search) => search.campaign_report_influencer_id === influencer.id).length,

          total_cost: !influencer.prices
            ? 0
            : this.data.tasks
              .filter((search) => search.campaign_report_influencer_id === influencer.id)
              .reduce((a, b) => a + Number(influencer.prices[b.type] || 0), 0)
        }
      })
    }
  },

  // Define local method functions
  methods: {
    /**
     * Trigger and handle the delete network request for the selected influencers
     *
     * @returns {void}
     */
    async handleDeleteRequest() {
      // Set the loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingDeleteRequest = true
      this.isMakingRequest = true

      // Try making the network request
      try {
        await axios({
          url: `/api/influencer-outreach/${this.data.model.id}/influencers`,
          method: "DELETE",
          data: {
            models: this.selectedItems.map((item) => item.id)
          }
        })

        // If succeeded, show a message
        this.$store.dispatch("toasts/add", { text: "Influencers removed! Refreshing your campaign." })

        // Trigger an event to reload the data
        this.$emit("refreshData")
      }
      // Catch the error
      catch (error) {
        // Log the error
        logger({ type: "InfluencerOutreach/Influencers Delete Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred, please try again!" })
      }
      // Nevertheless
      finally {
        // Remove the loaders
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingDeleteRequest = false
        this.isMakingRequest = false
      }
    },

    /**
     * Handle the action to update status value for this InfluencerOutreach
     *
     * @returns {void}
     */
     async handleStatusChange(value) {
      // Set the loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingUpdateRequest = true
      this.isMakingRequest = true

      // Try making the network request
      try {
        await axios({
          url: `/api/influencer-outreach/${this.data.model.id}/status`,
          method: "PUT",
          data: {
            status: value
          }
        })

        // If succeeded, show a message
        this.$store.dispatch("toasts/add", { text: "Campaign updated!" })

        // Update the local store value
        this.$store.dispatch("influencerOutreach/updateCampaign", { ...this.data.model, status: value })
      }
      // Catch the error
      catch (error) {
        // Log the error
        logger({ type: "InfluencerOutreach/Status Update Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred, please try again!" })
      }
      // Nevertheless
      finally {
        // Remove the loaders
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingUpdateRequest = false
        this.isMakingRequest = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.contain-select-width
  max-width 10em
</style>
