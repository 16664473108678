<template>
  <div class="overview-word-cloud">
    <div class="text-h6 font-weight-bold mb-4">
      Word Cloud
      <v-tooltip>
        <template v-slot:activator="{ on, attrs }">
          <v-icon :color="isThemeLight ? 'black' : null" v-bind="attrs" v-on="on"> help_outline </v-icon>
        </template>
        <span> Influencer relevant keywords cloud. </span>
      </v-tooltip>
    </div>

    <v-card class="border-radius-1em box-shadow-soft">
      <v-card-text>
        <word-cloud-component :words="wordsList" :height="283" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// Import node packages
import Chance from "chance"

// Import the colors list
import wordCloudColors from "@/json/wordCloudColors.js"
const WordCloudComponent = () => import(/* webpackChunkName: "word-cloud-component" */ "@/components/common/WordCloud.vue")

// Export the SFC
export default {
  // Name of the component
  name: "WordCloud",

  // Accept incoming data from parent
  props: {
    userData: {
      type: Object,
      required: true
    }
  },

  // Register the components
  components: {
    WordCloudComponent
  },

  // Local data variables
  data: () => ({
    colorsList: wordCloudColors()
  }),

  // Define readonly computable variables
  computed: {
    /**
     * Get all the words to be shown in the word cloud
     *
     * @returns {Array}
     */
    wordsList() {
      return this.userData.data.user_profile.relevant_tags
        .map((item) => ({
          text: item.tag,
          size: Math.round((1 - item.distance) * item.freq * 9),
          color: new Chance(item.tag[0]).pickone(this.colorsList)
        }))
        .slice(0, 50)
    }
  }
}
</script>

<style lang="stylus" scoped>
@media screen and (min-width: 1264px)
  .overview-word-cloud
    .v-card
      height 315px
</style>
