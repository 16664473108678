<template>
  <div>
    <!-- In case we're showing a preview -->
    <div v-if="!isAuthenticated" class="mb-8">
      <!-- Show the campaign logo, if any -->
      <v-img v-if="overview.model.logo" :src="campaignLogo" height="100" contain />

      <!-- Show the campaign name -->
      <div class="text-h6 font-weight-bold text-center primary--text mt-3">
        {{ overview.model.name }}
      </div>

      <!-- Show the date range -->
      <div class="text-center text-subtitle-2 mt-1">
        {{ dayjs(overview.model.start_date).format("ll") }} - {{ dayjs(overview.model.end_date).format("ll") }}
      </div>
    </div>

    <!-- The header buttons -->
    <div class="d-flex justify-space-between">
      <!-- Show the go back button -->
      <v-btn
        v-if="isAuthenticated"
        text
        color="primary"
        :disabled="isMakingRequest"
        @click="$router.push({ name: 'CampaignTrackingIndex' })"
      >
        <v-icon left> arrow_back </v-icon>

        Campaigns
      </v-btn>
      <!-- Don't show anything in the left -->
      <span v-else></span>

      <div class="d-flex">
        <template v-if="isDesktopDevice">
          <!-- Show the posts button -->
          <v-btn text class="mr-3" color="primary" :disabled="isMakingRequest" @click="$router.push({ name: 'CampaignTrackingPosts' })">
            <v-icon left> collections </v-icon>

            Posts
          </v-btn>

          <!-- Show the stories button -->
          <v-btn
            v-if="overview.model.platforms.includes('instagram')"
            text
            class="mr-3"
            color="primary"
            :disabled="isMakingRequest"
            @click="$router.push({ name: 'CampaignTrackingStories' })"
          >
            <v-icon left> motion_photos_on </v-icon>

            Stories
          </v-btn>

          <!-- Show the analytics button -->
          <v-btn text color="primary" :disabled="isMakingRequest" @click="$router.push({ name: 'CampaignTrackingAnalytics' })">
            <v-icon left> analytics </v-icon>

            Analytics
          </v-btn>
        </template>

        <!-- Show the menu here -->
        <v-menu v-if="isAuthenticated" :disabled="isMakingRequest" transition="slide-y-transition" offset-y bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" :disabled="isMakingRequest" v-bind="attrs" v-on="on">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>

          <!-- Show the menu options -->
          <v-list width="160" dense>
            <!-- Show the edit button -->
            <v-list-item v-if="overview.canUserWrite" @click="$router.push({ name: 'CampaignTrackingEdit' })">
              <v-list-item-content>
                <v-list-item-title> Edit </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Show the buttons on desktop -->
            <template
              v-if="!isDesktopDevice"
            >
              <v-list-item
                :disabled="isMakingRequest"
                @click="$router.push({ name: 'CampaignTrackingPosts' })"
              >
                <v-list-item-content>
                  <v-list-item-title> Posts </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="overview.model.platforms.includes('instagram')"
                :disabled="isMakingRequest"
                @click="$router.push({ name: 'CampaignTrackingStories' })"
              >
                <v-list-item-content>
                  <v-list-item-title> Stories </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                :disabled="isMakingRequest"
                @click="$router.push({ name: 'CampaignTrackingAnalytics' })"
              >
                <v-list-item-content>
                  <v-list-item-title> Analytics </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <!-- Show the option to trigger share -->
            <v-list-item
              v-if="overview.canUserWrite"
              @click="shouldShowShareDialog = true"
            >
              <v-list-item-content>
                <v-list-item-title> Share </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Show the option to trigger export as XLSX -->
            <v-list-item
              v-if="overview.canUserWrite"
              :disabled="isRefreshingPosts"
              @click="$emit('refreshPosts')"
            >
              <v-list-item-content>
                <v-list-item-title> Refresh Posts </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Show the option to trigger export as XLSX -->
            <v-list-item
              @click="shouldShowDownloadConfirmation = true"
              :disabled="isMakingDownloadRequest.pdf"
            >
              <v-list-item-content>
                <v-list-item-title> Download PDF </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Show the option to trigger export as XLSX -->
            <v-list-item
              @click="triggerDownload('xlsx')"
              :disabled="isMakingDownloadRequest.xlsx"
            >
              <v-list-item-content>
                <v-list-item-title> Download XLSX </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <!-- Show the buttons here -->
    <v-row class="mt-8">
      <v-spacer />

      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <div
          v-if="overview.canUserWrite"
          class="d-flex align-center justify-center justify-md-end"
        >
          <!-- Show the button to add post -->
          <v-btn
            depressed
            class="mr-3"
            color="primary"
            :disabled="isMakingRequest"
            @click="
              () => {
                selectedInfluencer = null
                shouldShowAttachPostDialog = true
              }
            "
          >
            <v-icon left> add </v-icon>

            Add Post
          </v-btn>

          <!-- Show the button to add influencer -->
          <v-btn depressed color="primary" :disabled="isMakingRequest" @click="$emit('triggerAddInfluencer')">
            <v-icon left> add </v-icon>

            Add Influencer
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- Show the stats values -->
    <overview :daywise="daywise" :overview="overview" :categories="categories" module="campaignTracking" show-annotations class="mt-6" />

    <!-- Show the influencers table -->
    <influencers-table
      class="mt-6"
      :overview="overview"
      module="campaignTracking"
      :should-show-select="true"
      :should-show-actions="true"
      :should-show-add-to-group="true"
      :should-show-unpublished-filter="true"
      @loading="(v) => (isMakingRequest = v)"
      @refresh="(v) => $emit('refreshData')"
      @triggerAddPost="
        (item) => {
          selectedInfluencer = item
          shouldShowAttachPostDialog = true
        }
      "
    />

    <!-- If we should show the invite form -->
    <template
      v-if="overview.canUserWrite && isInviteFormAllowed"
    >
      <!-- Show a divider -->
      <v-divider class="my-12"></v-divider>

      <!-- Show the container -->
      <invite-form-overview
        :overview="overview"
        module="campaignTracking"
      />
    </template>

    <!-- Show an attach post dialog -->
    <v-dialog v-model="shouldShowAttachPostDialog" :persistent="isMakingAttachPostRequest" max-width="500">
      <v-card>
        <v-card-title class="primary white--text">
          Add a post {{ selectedInfluencer ? `for ${selectedInfluencer.username}` : "" }}
        </v-card-title>

        <v-card-text class="pt-6">
          <v-text-field v-model="form.postURL" label="Enter Post URL" color="primary" hide-details outlined></v-text-field>
        </v-card-text>

        <v-card-actions class="pb-4 pr-6">
          <v-spacer />

          <v-btn
            depressed
            color="primary"
            :disabled="isMakingAttachPostRequest"
            :loading="isMakingAttachPostRequest"
            @click="handleAttachPost"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show a dialog to confirm download report type -->
    <v-dialog v-model="shouldShowDownloadConfirmation" :persistent="isMakingDownloadRequest.pdf" max-width="500">
      <v-card>
        <v-card-title class="primary white--text"> Campaign Tracking Report </v-card-title>

        <v-card-text class="pt-6">
          <p>How do you want to download this report as?</p>

          <p><b>Basic reporting</b> would only include the basic stat values and the post screenshots.</p>
          <p><b>Advanced reporting</b> would also include detailed analytics for each influencer and post if you've unlocked them.</p>
        </v-card-text>

        <v-card-actions>
          <v-btn text color="primary" :disabled="isMakingDownloadRequest.pdf" @click="shouldShowDownloadConfirmation = false">
            Cancel
          </v-btn>

          <v-spacer />

          <v-btn text class="mr-3" color="primary" :disabled="isMakingDownloadRequest.pdf" @click="triggerDownload('pdf', 'basic')">
            Basic
          </v-btn>

          <v-btn text color="primary" :disabled="isMakingDownloadRequest.pdf" @click="triggerDownload('pdf', 'advanced')"> Advanced </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show a dialog for managing sharing access -->
    <share-dialog
      v-model="shouldShowShareDialog"
      module="campaignTracking"
      :overview="overview"
    />
  </div>
</template>

<script>
// Import helper functions
import messageEvents from "@/helpers/messageEvents"
import { getClientID } from "@/helpers/clientHelper"
import { url } from "vuelidate/lib/validators"

// Import children components
const InviteFormOverview = () => import(/* webpackChunkName: "crm-invite-forms-overview" */ "@/components/crm/invite-form/Overview")
const InfluencersTable = () => import(/* webpackChunkName: "crm-influencers-table" */ "@/components/crm/InfluencersTable.vue")
const ShareDialog = () => import(/* webpackChunkName: "crm-share-dialog" */ "@/components/crm/ShareDialog")
const Overview = () => import(/* webpackChunkName: "crm-overview" */ "@/components/crm/Overview")

// Subscription ID for messageEvents
const subscriptionId = Symbol("CampaignTracking/Influencers")

// Export the SFC
export default {
  // Name of the SFC
  name: "CampaignTrackingInfluencers",

  // Register children components
  components: {
    InviteFormOverview,
    InfluencersTable,
    ShareDialog,
    Overview
  },

  // Accept incoming data from parent
  props: {
    daywise: {
      type: Object,
      required: true
    },

    overview: {
      type: Object,
      required: true
    },

    categories: {
      type: Array,
      required: true
    },

    isRefreshingPosts: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  // Define local data variables
  data: () => ({
    // Whether or not is the app making a network request
    isMakingRequest: false,
    // Whether or not is the app making an update request
    isMakingUpdateRequest: false,
    // Whether or not to show the download confirmation dialog
    shouldShowDownloadConfirmation: false,

    // Whether or not to show the shareable options dialog
    shouldShowShareDialog: false,

    // The selected influencer to whom to attach the post
    selectedInfluencer: null,
    // Whether or not to show the dialog
    shouldShowAttachPostDialog: false,
    // Whether or not is attaching a post
    isMakingAttachPostRequest: false,

    // Whether or not is making a download
    isMakingDownloadRequest: {
      pdf: false,
      xlsx: false
    },
    triggerId: {
      pdf: null,
      xlsx: null
    },
    downloadLoaders: {
      pdf: null,
      xlsx: null
    },

    form: {
      postURL: ""
    },
  }),

  // Computable readonly data variables
  computed: {
    /**
     * Whether or not the user is authenticated
     *
     * @returns {Boolean}
     */
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"]
    },

    /**
     * Get the campaign logo
     *
     * @returns {String}
     */
    campaignLogo() {
      return this.overview.model.logo ? `${process.env.VUE_APP_STORAGE_BASE}/${this.overview.model.logo}` : null
    },

    /**
     * Whether or not to show the invite form
     *
     * @return {Boolean}
     */
    isInviteFormAllowed() {
      return this.$store.getters['auth/isServiceAllowed'](constants.model.user.allowedServices.actionInviteForm)
    },
  },

  // Define local method functions
  methods: {
    /**
     * Make a network request to attach post
     *
     * @returns {void}
     */
    async handleAttachPost() {
      // Validate that the input is valid
      if (this.form.postURL.length === 0 || url(this.form.postURL) === false) {
        // Show a message
        this.$store.dispatch("toasts/add", { text: "Please enter a valid URL" })

        // Stop further execution
        return
      }

      // Otherwise, show a loader
      const loaderId = Symbol()
      this.isMakingAttachPostRequest = true
      this.$store.dispatch("loaders/add", loaderId)

      // Try making a request
      try {
        // Use helper function
        await axios({
          url: `/api/campaign-tracking/${this.overview.model.id}/posts`,
          data: {
            url: this.form.postURL.trim(),
            influencer_id: this.selectedInfluencer?.id || null
          },
          method: "POST"
        })

        // Show a message
        this.$store.dispatch("toasts/add", { text: "Post added to the campaign!" })

        // Reset the form
        this.form.postURL = ""

        // Reload the data
        window.dispatchEvent(new CustomEvent("reloadCampaignTrackingView", { detail: { id: this.overview.model.id } }))
      } catch (error) {
        // Catch any error
        // Log the response
        logger({ type: "CampaignTracking/AttachPost Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred" })
      } finally {
        // Nonetheless
        this.isMakingAttachPostRequest = false
        this.shouldShowAttachPostDialog = false
        this.$store.dispatch("loaders/remove", loaderId)
      }
    },

    /**
     * Handle the request to export this campaign as PDF
     *
     * @param {String} type
     * @returns {void}
     */
    async triggerDownload(type, reportType = "basic") {
      // Show a global loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingDownloadRequest[type] = true

      // Make a network request
      try {
        // Set a triggerId
        this.triggerId[type] = String(Date.now())

        // Use helper function
        await axios({
          url: `/api/campaign-tracking/${this.overview.model.id}/download/${type}?reportType=${reportType}`,
          method: "POST",
          data: {
            clientId: getClientID(),
            triggerId: this.triggerId[type]
          }
        })
      } catch (error) {
        // Catch the error
        // Show a message
        this.$store.dispatch("toasts/add", { text: "An error occurred, please try later." })

        // Hide the loader
        this.isMakingDownloadRequest[type] = false
      } finally {
        // Nonetheless
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)

        // Show a message
        this.$store.dispatch("toasts/add", { text: "Please wait while we generate your report..." })

        // If the type is "pdf"
        if (type === "pdf") {
          // Hide the dialog
          this.shouldShowDownloadConfirmation = false
        }
      }
    },

    /**
     * Stop the download progress
     *
     * @param {Event} event
     * @returns {void}
     */
    handleMessageEvent(event) {
      // If the clientId does not match
      if (getClientID() !== event.localData.clientId) {
        // Stop further execution
        return
      }

      // Switch through the cases
      switch (event.key) {
        case "generate-campaign-tracking-pdf-started":
          // Check if the triggerId does not match
          if (this.triggerId.pdf !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a global loader
          this.downloadLoaders.pdf = Symbol()
          this.$store.dispatch("loaders/add", this.downloadLoaders.pdf)

          break

        case "generate-campaign-tracking-pdf-completed":
          // Check if the triggerId does not match
          if (this.triggerId.pdf !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "Downloading your report..." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaders.pdf)
          this.isMakingDownloadRequest.pdf = false

          break

        case "generate-campaign-tracking-pdf-failed":
          // Check if the triggerId does not match
          if (this.triggerId.pdf !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "An error occurred, please contact us." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaders.pdf)
          this.isMakingDownloadRequest.pdf = false

          break

        case "generate-campaign-tracking-xlsx-started":
          // Check if the triggerId does not match
          if (this.triggerId.xlsx !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a global loader
          this.downloadLoaders.xlsx = Symbol()
          this.$store.dispatch("loaders/add", this.downloadLoaders.xlsx)

          break

        case "generate-campaign-tracking-xlsx-completed":
          // Check if the triggerId does not match
          if (this.triggerId.xlsx !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "Downloading your report..." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaders.xlsx)
          this.isMakingDownloadRequest.xlsx = false

          break

        case "generate-campaign-tracking-xlsx-failed":
          // Check if the triggerId does not match
          if (this.triggerId.xlsx !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "An error occurred, please contact us." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaders.xlsx)
          this.isMakingDownloadRequest.xlsx = false

          break
      }
    }
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  created() {
    // Register a subscriber for messageEvents
    messageEvents.register({
      id: subscriptionId,
      module: "campaign-tracking",
      type: "all",
      key: "all",
      validator: (event) => event.module === "campaign-tracking" && event.localData.modelId === this.overview.model.id,
      callback: this.handleMessageEvent
    })
  },

  /**
   * Before this component is about to be removed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // De-register the event listener
    messageEvents.deregister(subscriptionId)
  }
}
</script>

<style lang="stylus" scoped>
.contain-select-width
  max-width 10em
</style>
