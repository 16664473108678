export default {
  // An array of object
  filters: [],
  sort: null,
  audienceSource: null,
  // defaults to followers, could be engagements
  sortBy: "highest_followers",
  // defaults to instagram, could be youtube or tiktok
  selectedPlatform: "instagram",

  // API response data
  results: {
    tiktok: {
      accounts: [],
      currentPage: 1,
      savedSearch: null,
      total: 0
    },
    youtube: {
      accounts: [],
      currentPage: 1,
      savedSearch: null,
      total: 0
    },
    instagram: {
      accounts: [],
      currentPage: 1,
      savedSearch: null,
      total: 0
    },
    snapchat: {
      accounts: [],
      currentPage: 1,
      savedSearch: null,
      total: 0
    },
    twitter: {
      accounts: [],
      currentPage: 1,
      savedSearch: null,
      total: 0
    },
    linkedin: {
      accounts: [],
      currentPage: 1,
      savedSearch: null,
      total: 0
    }
  }
}
