<template>
  <div ref="wordCloudContainer" class="word-cloud-container"></div>
</template>

<script>
const d3 = require("d3")
const cloud = require("d3-cloud")

// Export the SFC
export default {
  // Name of the component
  name: "WordCloud",

  // Accept incoming data from parents
  props: {
    width: {
      type: Number,
      required: false,
      default: null
    },

    height: {
      type: Number,
      default: 300
    },

    words: {
      type: Array,
      required: true
    }
  },

  // Define local data variables
  data() {
    return {
      layout: null,
      retryCounter: 1
    }
  },

  // Define watchers
  watch: {
    words: {
      handler() {
        console.log("WORDS CHANGED", this.words.length)
        this.initiate()
      },

      deep: true
    }
  },

  // Define the props
  methods: {
    /**
     * Draw the word cloud
     *
     * @param {Array} words
     * @returns {void}
     */
    draw(words) {
      // If the words count is 0 and retryCounter is less than 5
      if (words.length === 0 && this.retryCounter < 5) {
        // Set a timeout
        setTimeout(() => {
          // Increment the retryCounter
          this.retryCounter++

          // Call the initiate method
          this.initiate()
        }, 500 * 1)

        // Return null
        return null
      }
      // Reset the retry counter
      this.retryCounter = 1

      d3.select(this.$refs.wordCloudContainer)
        .append("svg")
        .attr("width", this.layout.size()[0])
        .attr("height", this.layout.size()[1])
        .append("g")
        .attr("transform", "translate(" + this.layout.size()[0] / 2 + "," + this.layout.size()[1] / 2 + ")")
        .selectAll("text")
        .data(words)
        .enter()
        .append("text")
        .style("font-size", (d) => d.size + "px")
        .style("fill", (d) => d.color || "#000")
        .style("font-family", "Impact")
        .attr("text-anchor", "middle")
        .attr("transform", function (d) {
          return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")"
        })
        .text((d) => d.text)
    },

    /**
     * Clean the DOM element and D3 object
     *
     * @returns {void}
     */
    clean() {
      d3.select(this.$refs.wordCloudContainer).selectAll("*").remove()
    },

    /**
     * Initialize the word cloud
     *
     * @returns {void}
     */
    initiate() {
      this.clean()

      this.layout = cloud()
        .size([this.width || this.$refs.wordCloudContainer.clientWidth, this.height])
        .words(this.words)
        .padding(5)
        .random(() => 0.5)
        .rotate(function (word) {
          return (Math.floor(word.text.length * 3) - 1) * 90
        })
        .font("Impact")
        .fontSize((d) => d.size)
        .on("end", this.draw)

      this.layout.start()
    }
  },

  /**
   * Right after the component is first rendered
   *
   * @returns {void}
   */
  mounted() {
    // Call to create
    this.initiate()
  },

  /**
   * When the component is to be deleted
   *
   * @returns {void}
   */
  beforeDestroy() {
    this.clean()
  }
}
</script>
