<template>
  <div>
    <form-component
      :key="refreshKey"
      :value="value"
      :model="model"
      :module="module"
      @input="$emit('input', $event)"
      @close="handleClose"
    />
  </div>
</template>

<script>
// Import the children components
const FormComponent = () => import(/* webpackChunkName: "invite-form-component" */ "@/components/crm/invite-form/Form.vue")

// Export the SFC
export default {
  // Name of the component
  name: "InviteFormCreate",

  // Register the components
  components: {
    FormComponent
  },

  // Accept incoming data from parent
  props: {
    value: Boolean,
    model: Object,
    module: String
  },

  // Define local data variables
  data: () => ({
    refreshKey: Symbol()
  }),

  // Define local method functions
  methods: {
    /**
     * Handle the close event
     *
     * @return {void}
     */
    handleClose() {
      this.$emit("input", false)
      this.refreshKey = Symbol()
    }
  }
}
</script>
