<template>
  <div class="standard-page">
    <!-- If we don't have the InfluencerOutreach data just yet -->
    <template v-if="!overview">
      <div
        class="mx-auto"
        style="max-width: 360px"
      >
        <lottie-animation
          loop
          key="antenna"
          file="loading-files-StOdYbIRa1.json"
        />
      </div>
    </template>

    <!-- Otherwise -->
    <template v-else>
      <!-- If we don't have any influencer in this campaign -->
      <template v-if="overview.influencers.length === 0">
        <!-- The header buttons -->
        <div class="d-flex justify-space-between">
          <!-- Show the go back button -->
          <v-btn
            text
            color="primary"
            @click="$router.push({ name: 'InfluencerOutreachIndex' })"
          >
            <v-icon left>
              arrow_back
            </v-icon>

            Campaigns
          </v-btn>
        </div>

        <!-- Show an animation first -->
        <div class="mx-auto" style="max-width: 360px">
          <lottie-animation
            loop
            key="shaking-box"
            file="106964-shake-a-empty-box.json"
          />
        </div>

        <!-- Show the text -->
        <div class="text-center text-h6 font-weight-bold">
          You don't have any influencer in this Campaign
        </div>

        <div class="text-center mt-2">
          Start by adding influencers below
        </div>

        <!-- Show the button -->
        <div class="text-center">
          <v-btn
            depressed
            class="mt-6"
            color="primary"
            @click="shouldShowAddInfluencerDialog = true"
          >
            <v-icon left>
              add
            </v-icon>

            Add Influencers
          </v-btn>
        </div>
      </template>

      <!-- Otherwise, show the router-view child component -->
      <div
        v-else
        class="influencer-outreach-child-view"
      >
        <router-view
          :data="overview"
          :key="reloadKey"
          @refreshData="fetchDetails"
          @triggerAddInfluencer="shouldShowAddInfluencerDialog = true"
        />
      </div>

      <!-- Show the add influencers dialog -->
      <add-influencers
        v-if="isAuthenticated"
        v-model="shouldShowAddInfluencerDialog"
        :selected-platform="selectedPlatform"
        :overview="overview"
        :include-posts-input="true"
        module="influencerOutreach"
        @refresh="fetchDetails"
      />
    </template>
  </div>
</template>

<script>
// Import children components
const AddInfluencers = () => import(/* webpackChunkName: "crm-add-influencers" */ "@/components/crm/AddInfluencers.vue")
const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")
const ProfileSelector = () => import(/* webpackChunkName: "profile-selector" */ "@/blocks/common/selectors/ProfileSelector.vue")
const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/blocks/common/selectors/PlatformSelector.vue")

// Import helper functions
import platformRegex from "@/helpers/platformRegex"
import messageEvents from "@/helpers/messageEvents"
import { required, maxLength, minLength } from "vuelidate/lib/validators"

// Define the generator function for formData
const generateFormData = () => ({
  platform: "instagram",
  username: ""
})

// Define the key for handling subscriptions
const subscriptionId = Symbol("InfluencerOutreach/View")

// Export the SFC
export default {
  // Name of the component
  name: "InfluencerOutreachView",

  // Register children components
  components: {
    AddInfluencers,
    LottieAnimation,
    ProfileSelector,
    PlatformSelector
  },

  // Define local data variables
  data: () => ({
    // Whether or not to show the add influencer dialog
    shouldShowAddInfluencerDialog: false,

    // If the form is being submitted
    isMakingRequest: false,

    // If it's uploading the spreadsheet file
    isUploading: false,

    // If the data is being loaded
    isLoading: false,

    // The reload key to make sure the UI is always up-to-date
    reloadKey: Symbol(),

    // Store the form data for the request
    formData: generateFormData()
  }),

  // Define readonly data variables
  computed: {
    /**
     * Whether or not the user is authenticated
     *
     * @returns {Boolean}
     */
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"]
    },

    /**
     * Get the model data from Vuex store
     *
     * @returns {Object}
     */
    overview() {
      return this.$store.getters["influencerOutreach/detailByUuid"](this.$route.params.uuid)
    },

    /**
     * If the code is making a network request, disable the buttons and inputs
     *
     * @returns {Boolean}
     */
    shouldFormBeDisabled() {
      return this.isMakingRequest || this.isUploading
    },

    /**
     * Get the currently selected platform
     *
     * @returns {String|Null}
     */
    selectedPlatform() {
      return this.overview ? this.$store.getters["influencerOutreach/selectedPlatformById"](this.overview.model.id) : null
    },
  },

  // Define vuelidate validations object
  validations: {
    formData: {
      username: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(200)
      }
    }
  },

  /**
   * Handle the form submit event to add the influencer to the model
   *
   * @returns {void}
   */
  methods: {
    /**
     * When called, fetch and update the campaign data in Vuex store
     *
     * @returns {void}
     */
    async fetchDetails() {
      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isLoading = true

      // Wait for the load to be completed
      await this.$store.dispatch("influencerOutreach/fetchDetails", this.$route.params.uuid)

      // If there's no selected platform
      if (!this.selectedPlatform) {
        // Set the default value for platforms
        this.$store.dispatch("influencerOutreach/updateSelectedPlatform", {
          id: this.overview.model.id,
          value: this.overview?.model?.platforms?.[0] || null
        })
      }

      // Hide the loaders
      this.$store.dispatch("loaders/remove", loaderId)
      this.isLoading = false

      // Also, reload the children view
      this.reloadKey = Symbol()
    },

    /**
     * When triggered, refresh the data to fetch updated list of influencers
     *
     * @returns {void}
     */
    handleNotificationRefreshAction() {
      // Dispatch a request to create a network request
      this.fetchDetails()
    },

    /**
     * Handle the message event for campaign views
     *
     * @param {Object} event
     * @returns {void}
     */
    handleMessageEvent(event) {
      // If the import just started
      if (event.key === "influencers-import-started") {
        // Fire a notification
        this.$store.dispatch("notifications/add", {
          text: event.localData.text || "Your influencers are being added to the campaign!",
          type: "info",
          icon: "lightbulb",
          event: {
            module: event.module,
            type: event.type,
            key: event.key
          }
        })
      }

      // If a chunk of import has been completed
      if (event.key === "influencers-import-completed") {
        // Fire a notification
        this.$store.dispatch("notifications/add", {
          text: event.localData.text || "A bunch of your influencers have been added, check progress!",
          type: "success",
          icon: "check_circle",
          button: {
            text: "Refresh",
            action: this.handleNotificationRefreshAction
          },
          event: {
            module: event.module,
            type: event.type,
            key: event.key
          }
        })
      }
    }
  },

  /**
   * As soon as the component data has been created
   *
   * @returns {void}
   */
  created() {
    // Dispatch a request to create a network request
    this.fetchDetails()

    // Register a subscriber for messageEvents
    messageEvents.register({
      id: subscriptionId,
      module: "influencer-outreach",
      type: "all",
      key: "all",
      validator: (event) => event.module === "influencer-outreach",
      callback: this.handleMessageEvent
    })
  },

  /**
   * Right before the component is about to be un-rendered
   *
   * @returns {void}
   */
  beforeDestroy() {
    // De-register the subscriber for messageEvents
    messageEvents.deregister(subscriptionId)
  }
}
</script>