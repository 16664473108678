// Export the getters object
export default {
  /**
   * Return the InfluencerOutreach items
   *
   * @param {Object} state
   * @returns {Array}
   */
  items(state) {
    return state.items
  },

  /**
   * Return the InfluencerOutreach detailed items
   *
   * @param {Object} state
   * @returns {Array}
   */
  details(state) {
    return state.details
  },

  /**
   * Get the InfluencerOutreach detailed object
   *
   * @param {Object} state
   * @param {String} uuid
   * @returns {Object}
   */
  detailByUuid: (state) => (uuid) => {
    return state.details.find(search => search.model.uuid === uuid)
  },

  /**
   * Return the selected platform
   *
   * @param {Object} state
   * @returns {String}
   */
  selectedPlatformById: (state) => (id) => {
    return state.selectedPlatforms.find((search) => search.id === id)?.value || null
  },
}
