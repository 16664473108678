<template>
  <div class="standard-page">
    <v-card
      flat
      :loading="isLoadingUsage"
    >
      <v-card-text>
        <usage-chart
          v-if="usageData"
          :data="usageData"
        />
      </v-card-text>
    </v-card>

    <!-- File and text inputs -->
    <v-card
      flat
      :disabled="isMakingNetworkRequest"
      :loading="isMakingNetworkRequest"
      class="mt-4 mt-md-6"
    >
      <v-card-text>
        <template v-if="isWhitelisted">
          <div class="overline mb-2">
            Logo & Watermark
          </div>

          <v-row class="mb-3">
            <!-- For the logo -->
            <v-col cols="12" lg="6">
              <v-row>
                <v-col cols="12" md="4" lg="3">
                  <!-- Show the logo if available -->
                  <div
                    class="checkerboard-background min-width-100"
                    style="height: 88px;"
                  >
                    <v-img
                      v-if="previewLogo"
                      :src="previewLogo"
                      height="88"
                      class="mb-4 checkerboard-background"
                      contain
                    />
                  </div>
                </v-col>

                <v-col cols="12" md="8" lg="9">
                  <v-card
                    outlined
                    class="d-flex justify-space-between align-center"
                  >
                    <div>
                      <v-card-title>
                        Upload Logo
                      </v-card-title>

                      <v-card-subtitle
                        :class="{ 'red--text': $v.formData.logo.$anyError }"
                        class="text-caption"
                      >
                        Please select an image under 1MB
                      </v-card-subtitle>
                    </div>

                    <v-card-actions class="pr-4">
                      <!-- Show the upload logo field -->
                      <input
                        type="file"
                        name="logo"
                        accept=".png, .jpeg, .jpg"
                        class="d-none"
                        ref="logoInput"
                        @change="handleLogoChange"
                      />

                      <v-btn
                        color="primary"
                        depressed
                        @click="$refs.logoInput && $refs.logoInput.click()"
                        :loading="isMakingNetworkRequest"
                        :disabled="isMakingNetworkRequest"
                      >
                        <v-icon left>image</v-icon>
                        Upload
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>

            <!-- For the watermark -->
            <v-col cols="12" lg="6">
              <v-row>
                <v-col cols="12" md="4" lg="3">
                  <!-- Show the watermark if available -->
                  <div
                    class="checkerboard-background min-width-100"
                    style="height: 88px;"
                  >
                    <v-img
                      v-if="previewWatermark"
                      :src="previewWatermark"
                      height="88"
                      class="mb-4 checkerboard-background"
                      contain
                    />
                  </div>
                </v-col>

                <v-col cols="12" md="8" lg="9">
                  <v-card
                    outlined
                    class="d-flex justify-space-between align-center"
                  >
                    <div>
                      <v-card-title>
                        Upload Watermark
                      </v-card-title>

                      <v-card-subtitle
                        :class="{ 'red--text': $v.formData.logo.$anyError }"
                        class="text-caption"
                      >
                        Please select an image under 1MB
                      </v-card-subtitle>
                    </div>

                    <v-card-actions class="pr-4">
                      <!-- Show the upload logo field -->
                      <input
                        type="file"
                        name="logo"
                        accept=".png, .jpeg, .jpg"
                        class="d-none"
                        ref="watermarkInput"
                        @change="handleWatermarkChange"
                      />

                      <v-btn
                        color="primary"
                        depressed
                        @click="$refs.watermarkInput && $refs.watermarkInput.click()"
                        :loading="isMakingNetworkRequest"
                        :disabled="isMakingNetworkRequest"
                      >
                        <v-icon left>image</v-icon>
                        Upload
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-divider class="my-6" />
        </template>

        <div class="overline mb-2">
          Personal Details
        </div>

        <v-row>
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="formData.name"
              :hide-details="!$v.formData.name.$anyError"
              :error-messages="$v.formData.name.$anyError ? ['Name is required'] : null"
              @blur="$v.formData.name.$touch"
              autocomplete="user-name"
              label="Name"
              outlined
              dense
            />
          </v-col>

          <v-col cols="12" lg="4">
            <v-text-field
              v-model="formData.phone"
              :hide-details="!$v.formData.phone.$anyError"
              :error-messages="$v.formData.phone.$anyError ? ['Please enter a correct phone number'] : null"
              @blur="$v.formData.phone.$touch"
              autocomplete="user-phone"
              label="Phone"
              outlined
              dense
            />
          </v-col>

          <v-col
            v-if="isWhitelisted"
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="formData.companyName"
              :hide-details="!$v.formData.companyName.$anyError"
              @blur="$v.formData.companyName.$touch"
              autocomplete="company-name"
              label="Company Name"
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="formData.currentPassword"
              :hide-details="!$v.formData.currentPassword.$anyError"
              :error-messages="$v.formData.currentPassword.$anyError ? ['Please enter a valid password'] : null"
              @blur="$v.formData.currentPassword.$touch"
              autocomplete="user-currentPassword"
              :append-icon="shouldPasswordBeVisible.current ? 'visibility' : 'visibility_off'"
              :type="shouldPasswordBeVisible.current ? 'text' : 'password'"
              @click:append="shouldPasswordBeVisible.current = !shouldPasswordBeVisible.current"
              label="Current Password"
              outlined
              counter
              dense
            />
          </v-col>

          <v-col cols="12" lg="4">
            <v-text-field
              v-model="formData.newPassword"
              :hide-details="!$v.formData.newPassword.$anyError"
              :error-messages="$v.formData.newPassword.$anyError ? ['Please enter a valid password'] : null"
              @blur="$v.formData.newPassword.$touch"
              autocomplete="user-newPassword"
              :append-icon="shouldPasswordBeVisible.new ? 'visibility' : 'visibility_off'"
              :type="shouldPasswordBeVisible.new ? 'text' : 'password'"
              @click:append="shouldPasswordBeVisible.new = !shouldPasswordBeVisible.new"
              label="New Password"
              outlined
              counter
              dense
            />
          </v-col>

          <v-col cols="12" lg="4">
            <v-text-field
              v-model="formData.confirmPassword"
              :hide-details="!$v.formData.confirmPassword.$anyError"
              :error-messages="$v.formData.confirmPassword.$anyError ? ['Password does not match'] : null"
              @blur="$v.formData.confirmPassword.$touch"
              autocomplete="user-confirmPassword"
              :append-icon="shouldPasswordBeVisible.confirm ? 'visibility' : 'visibility_off'"
              :type="shouldPasswordBeVisible.confirm ? 'text' : 'password'"
              @click:append="shouldPasswordBeVisible.confirm = !shouldPasswordBeVisible.confirm"
              label="Confirm Password"
              outlined
              counter
              dense
            />
          </v-col>
        </v-row>

        <template v-if="canUseAPI">
          <v-divider class="my-6" />

          <div class="overline">
            API Access
          </div>

          <v-combobox
            v-model="formData.IPAddresses"
            label="Whitelisted IP Addresses"
            :allow-overflow="false"
            placeholder="Use enter to save value"
            multiple
            outlined
            dense
            small-chips
            deletable-chips

            :hide-details="!$v.formData.IPAddresses.$anyError"
            :error-messages="$v.formData.IPAddresses.$anyError ? ['Please enter valid IP values'] : null"
            @input="$v.formData.IPAddresses.$touch"
          ></v-combobox>
        </template>
      </v-card-text>

      <v-card-actions class="pb-6 pr-4">
        <v-spacer></v-spacer>

        <v-btn
          depressed
          color="primary"
          @click="handleSubmit"
          :disabled="isMakingNetworkRequest"
          :loading="isMakingNetworkRequest"
        >
          Update

          <v-icon right>sync</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Email managements switches -->
    <v-card
      flat
      class="mt-4 mt-md-6"
    >
      <v-card-text>
        <div class="overline">
          Manage Email Notifications
        </div>

        <v-list class="mt-4">
          <v-list-item-group>
            <v-list-item
              v-for="item in emailItems"
              :key="item.key"
            >
              <v-list-item-action>
                <v-switch
                  value
                  inset
                  hide-details
                  :input-value="item.value"
                  @change="toggleEmailMeta(item.key, item.value)"
                />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>

                <v-list-item-subtitle>
                  {{ item.text }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// Import helper validation functions
import subnetValidator from "@/helpers/validators/subnetValidator"
import { required, numeric, minLength, sameAs, ipAddress } from "vuelidate/lib/validators"

// Import children components
const UsageChart = () => import(/* webpackChunkName: "profile-usage-chart" */ "@/components/profile/UsageChart")

// Export the SFC
export default {
  // Name of the component
  name: "Profile",

  // Register the components
  components: {
    UsageChart
  },

  // Local data variables
  data: () => ({
    // Whether or not the request is being made
    isMakingNetworkRequest: false,

    // Whether to make password readable
    shouldPasswordBeVisible: {
      current: false,
      new: false,
      confirm: false
    },

    previewLogo: null,
    previewWatermark: null,

    formData: {
      logo: null,
      watermark: null,

      name: "",
      phone: "",
      companyName: "",
      newPassword: "",
      currentPassword: "",
      confirmPassword: "",
      IPAddresses: []
    },

    isLoadingUsage: false,
    usageData: null
  }),

  // Define computable properties
  computed: {
    /**
     * Get the profile object from Vuex store
     *
     * @returns {Object}
     */
    profileData() {
      return this.$store.getters["auth/profile"]
    },

    /**
     * Get the logo URL from profile in Vuex store
     *
     * @returns {String|Null}
     */
    currentUserLogo() {
      return this.$store.getters["auth/profileLogo"]
    },

    /**
     * Get the watermark URL from profile in Vuex store
     *
     * @returns {String|Null}
     */
    currentUserWatermark() {
      return this.$store.getters["auth/profileWatermark"]
    },

    /**
     * Whether or not the user is whitelisted
     *
     * @returns {Boolean}
     */
    isWhitelisted() {
      return this.$store.getters["auth/isWhitelisted"]
    },

    /**
     * Whether or not the user is allowed to use API
     *
     * @returns {Boolean}
     */
    canUseAPI() {
      return this.profileData && this.profileData.can_use_api
    },

    /**
     * Get the email notification items
     *
     * @returns {Array}
     */
    emailItems() {
      const defaultValue = "should-send"

      return [
        {
          key: constants.model.user.metas.email.influencerDiscoveryExportReady,
          title: "Influencer Discovery Exports",
          text: "Get notified when your export is generated",
          value: this.$store.getters["auth/getMetaValue"](constants.model.user.metas.email.influencerDiscoveryExportReady) === defaultValue
        },

        {
          key: constants.model.user.metas.email.influencerInsightExportReady,
          title: "Influencer Insight Exports",
          text: "Get notified when your bulk export is ready",
          value: this.$store.getters["auth/getMetaValue"](constants.model.user.metas.email.influencerInsightExportReady) === defaultValue,
          condition: this.$store.getters["auth/isServiceAllowed"](constants.model.user.allowedServices.actionBulkExportInfluencerInsightsPDF) || this.$store.getters["auth/isServiceAllowed"](constants.model.user.allowedServices.actionBulkExportInfluencerInsightsXLSX)
        },

        {
          key: constants.model.user.metas.email.paidCollaborationExportReady,
          title: "Paid Collaboration Exports",
          text: "Get notified when your export is ready to download",
          value: this.$store.getters["auth/getMetaValue"](constants.model.user.metas.email.paidCollaborationExportReady) === defaultValue,
          condition: this.$store.getters["auth/isServiceAllowed"](constants.model.user.allowedServices.paidCollaborations)
        },

        {
          key: constants.model.user.metas.email.audienceOverlapReady,
          title: "Audience Overlap Report",
          text: "Get notified when we're done computing your report",
          value: this.$store.getters["auth/getMetaValue"](constants.model.user.metas.email.audienceOverlapReady) === defaultValue,
          condition: this.$store.getters["auth/isServiceAllowed"](constants.model.user.allowedServices.audienceOverlap)
        },

        {
          key: constants.model.user.metas.email.socialSentimentReady,
          title: "Social Sentiment Report",
          text: "Get notified when we're done computing your report",
          value: this.$store.getters["auth/getMetaValue"](constants.model.user.metas.email.socialSentimentReady) === defaultValue,
          condition: this.$store.getters["auth/isServiceAllowed"](constants.model.user.allowedServices.socialSentiments)
        },

        {
          key: constants.model.user.metas.email.competitorCheckReady,
          title: "Influencer Collab Check Report",
          text: "Get notified when we're done checking your competitors",
          value: this.$store.getters["auth/getMetaValue"](constants.model.user.metas.email.competitorCheckReady) === defaultValue,
          condition: this.$store.getters["auth/isServiceAllowed"](constants.model.user.allowedServices.competitorCheck)
        },

        {
          key: constants.model.user.metas.email.contentDiscoveryReady,
          title: "Content Discovery Report",
          text: "Get notified when we're done discovering content for you",
          value: this.$store.getters["auth/getMetaValue"](constants.model.user.metas.email.contentDiscoveryReady) === defaultValue,
          condition: this.$store.getters["auth/isServiceAllowed"](constants.model.user.allowedServices.contentDiscovery)
        },

        {
          key: constants.model.user.metas.email.influencerGroupInfluencersImported,
          title: "Influencer Group Import",
          text: "Get notified when we're done importing influencers",
          value: this.$store.getters["auth/getMetaValue"](constants.model.user.metas.email.influencerGroupInfluencersImported) === defaultValue,
          condition: this.$store.getters["auth/isServiceAllowed"](constants.model.user.allowedServices.influencerGroups)
        },

        {
          key: constants.model.user.metas.email.campaignTrackingInfluencersImported,
          title: "Campaign Tracking Import",
          text: "Get notified when we're done importing influencers",
          value: this.$store.getters["auth/getMetaValue"](constants.model.user.metas.email.campaignTrackingInfluencersImported) === defaultValue,
          condition: this.$store.getters["auth/isServiceAllowed"](constants.model.user.allowedServices.campaignTracking)
        },

        {
          key: constants.model.user.metas.email.mentionTrackingReady,
          title: "Mention Tracking Report",
          text: "Get notified when we've prepared your report",
          value: this.$store.getters["auth/getMetaValue"](constants.model.user.metas.email.mentionTrackingReady) === defaultValue,
          condition: this.$store.getters["auth/isServiceAllowed"](constants.model.user.allowedServices.mentionTracking)
        },

        {
          key: constants.model.user.metas.email.reportShared,
          title: "Report Shared With You",
          text: "Get notified when someone shares a report with you",
          value: this.$store.getters["auth/getMetaValue"](constants.model.user.metas.email.reportShared) === defaultValue
        }
      ].filter((item) => item.condition !== false)
    }
  },

  /**
   * Define validation constraints for the form
   *
   * @returns {Object}
   */
  validations() {
    const validationsObject = {
      formData: {
        logo: {
          maxSize: (value) => {
            return value ? value.size <= 1_000_000 : true // max 1mb in size
          },
          mimeType: (value) => {
            return value ? ["image/png", "image/jpeg"].includes(value.type) : true
          }
        },
        watermark: {
          maxSize: (value) => {
            return value ? value.size <= 1_000_000 : true // max 1mb in size
          },
          mimeType: (value) => {
            return value ? ["image/png", "image/jpeg"].includes(value.type) : true
          }
        },
        name: {
          required
        },
        phone: {
          numeric
        },
        companyName: {},
        currentPassword: {
          minLength: minLength(8)
        },
        newPassword: {
          minLength: minLength(8)
        },
        confirmPassword: {
          sameAs: sameAs('newPassword')
        }
      }
    }

    // If the user is allowed to use API
    if (this.canUseAPI) {
      validationsObject.formData.IPAddresses = {
        $each: {
          IPorSubnet: (v) => ipAddress(v) || subnetValidator(v)
        }
      }
    }

    // If the user is whitelisted
    if (this.isWhitelisted) {
      validationsObject.formData.logo = {
        maxSize: (value) => {
          return value ? value.size <= 1_000_000 : true // Maximum 1MB in size
        },
        mimeType: (value) => {
          return value ? ["image/png", "image/jpeg"].includes(value.type) : true
        }
      }

      validationsObject.formData.watermark = {
        maxSize: (value) => {
          return value ? value.size <= 1_000_000 : true // Maximum 1MB in size
        },
        mimeType: (value) => {
          return value ? ["image/png", "image/jpeg"].includes(value.type) : true
        }
      }
    }

    // If the user has entered new password, make current password required
    if (this.formData.newPassword) {
      validationsObject.formData.currentPassword.required = required
    }

    return validationsObject
  },

  // Define local method functions
  methods: {
    /**
     * Emit event to close the dialog
     *
     * @returns {void}
     */
    emitClose() {
      this.$emit("close")
    },

    /**
     * Handle the event when the image changes in input
     *
     * @param {Event} event
     * @returns {void}
     */
    async handleLogoChange(event) {
      // Check if the event has any files in it
      if (!event.target.files || !event.target.files.length) {
        // Show an error message
        this.$store.dispatch("toasts/add", { text: "No file selected" })
      }

      // Get the file object
      this.formData.logo = event.target.files[0] || null

      // Run the validations
      await this.$v.formData.logo.$touch()

      // If an image is selected
      if (this.formData.logo && this.formData.logo instanceof File) {
        this.previewLogo = URL.createObjectURL(this.formData.logo)
      }
      // If an image has been removed, fallback to default
      else {
        this.previewLogo = this.currentUserLogo
      }
    },

    /**
     * Handle the event when the image changes in input
     *
     * @param {Event} event
     * @returns {void}
     */
    async handleWatermarkChange(event) {
      // Check if the event has any files in it
      if (!event.target.files || !event.target.files.length) {
        // Show an error message
        this.$store.dispatch("toasts/add", { text: "No file selected" })
      }

      // Get the file object
      this.formData.watermark = event.target.files[0] || null

      // Run the validations
      await this.$v.formData.watermark.$touch()

      // If an image is selected
      if (this.formData.watermark && this.formData.watermark instanceof File) {
        this.previewWatermark = URL.createObjectURL(this.formData.watermark)
      }
      // If an image has been removed, fallback to default
      else {
        this.previewWatermark = this.currentUserWatermark
      }
    },

    /**
     * Make network request to the server API
     *
     * @returns {void}
     */
    async handleSubmit() {
      await this.$v.$touch()

      if (this.$v.$anyError) {
        return false
      }

      this.isMakingNetworkRequest = true

      try {
        const formData = new FormData()
        formData.append("_method", "PUT")

        formData.append("name", this.formData.name)
        formData.append("phone", this.formData.phone)
        formData.append("company_name", this.formData.companyName)
        formData.append("new_password", this.formData.newPassword)
        formData.append("current_password", this.formData.currentPassword)
        formData.append("confirm_password", this.formData.confirmPassword)

        // compute what to do with the logo
        formData.append("logo_action", this.formData.logo === null ? "ignore" : "update")

        // if we don't have a logo to begin with, don't send anything
        if (this.formData.logo !== null) {
          formData.append("logo_file", this.formData.logo)
        }

        // compute what to do with the watermark
        formData.append("watermark_action", this.formData.watermark === null ? "ignore" : "update")

        // if we don't have a watermark to begin with, don't send anything
        if (this.formData.watermark !== null) {
          formData.append("watermark_file", this.formData.watermark)
        }

        // if the user is allowed to use API
        if (this.canUseAPI) {
          formData.append("ip_addresses", this.formData.IPAddresses)
        }

        await axios({
          url: "/api/profile",
          method: "POST",
          data: formData
        })

        // hide the dialog
        this.emitClose()

        // show the alert
        this.$store.dispatch("toasts/add", { text: "Account updated." })

        // let the app refresh user data
        window.dispatchEvent(new CustomEvent('fetchAuthUserProfile', {
          detail: {
            module: "Components/Profile/Edit",
            origin: "components/Profile/Edit/handleSubmit"
          }
        }))
      } catch (error) {
        logger({ type: "Update Profile Error", error })

        if (error.response.data.message) {
          this.$store.dispatch("toasts/add", { text: error.response.data.message })
        }
        else {
          this.$store.dispatch("toasts/add", { text: "Could not update account." })
        }
      } finally {
        this.isMakingNetworkRequest = false
      }
    },

    /**
     * Toggle the email meta value
     *
     * @param {String} key
     * @param {Boolean} value
     * @returns {void}
     */
    async toggleEmailMeta(key, value) {
      await this.$store.dispatch("auth/updateMeta", {
        key,
        value: value ? "ignore" : "should-send"
      })
    },

    /**
     * Fetch the usage data from the server
     *
     * @returns {void}
     */
    async fetchUsageData() {
      // If it's loading
      if (this.isLoadingUsage) {
        return
      }

      // Set the loading state
      this.isLoadingUsage = true

      try {
        // Make the network request
        const response = await axios({
          url: "/api/profile/usage"
        })

        // Set the usage data
        this.usageData = response.data
      }
      // Catch an error
      catch (error) {
        logger({ type: "Fetch Usage Data Error", error })

        // Show a toast
        this.$store.dispatch("toasts/add", { text: "Could not fetch usage data." })
      }
      // Nonetheless
      finally {
        // Set the loading state
        this.isLoadingUsage = false
      }
    }
  },

  /**
   * As soon as the component has been created
   *
   * @returns {void}
   */
  created() {
    // If there's a profile data
    if (this.profileData) {
      this.formData = {
        logo: null,
        watermark: null,
        name: this.profileData.name || "",
        phone: this.profileData.phone || "",
        companyName: this.profileData.company_name || "",
        newPassword: "",
        currentPassword: "",
        confirmPassword: "",
        IPAddresses: []
      }

      this.previewLogo = this.currentUserLogo
      this.previewWatermark = this.currentUserWatermark

      if (this.canUseAPI) {
        this.formData.IPAddresses = this.profileData.ip_addresses || []
      }
    }

    // Dispatch the request to fetch the usage data
    this.fetchUsageData()
  }
}
</script>
