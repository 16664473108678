const generator = () => ({
  hashtag: /#([\p{L}\p{M}\p{N}\p{So}_]{1,280})/ui,
  username: /@([A-Z._0-9]{1,29})/i,

  youtube: {
    video: [
      /youtube.com\/watch\/?\?v=([\w\.-]+)/i,
      /youtube.com\/shorts\/([\w\.-]+)/i,
      /youtu.be\/([\w\.-]+)/i
    ]
  }
})

export default generator()
