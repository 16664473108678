<template>
  <iframe
    :width="width"
    :height="height"
    :src="computedUrl"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    referrerpolicy="strict-origin-when-cross-origin"
    allowfullscreen
  ></iframe>
</template>

<script>
// Export the SFC
export default {
  // Name of the component
  name: "YoutubeEmbed",

  // Accept incoming data from parent
  props: {
    url: String,
    height: {
      type: [Number, String],
      default: 315
    },
    width: {
      type: [Number, String],
      default: 560
    }
  },

  // Define computed properties
  computed: {
    /**
     * Compute the URL for the YouTube embed video
     *
     * @returns {String} The URL for the YouTube video
     */
    computedUrl() {
      // Get the code from the URL
      const code = regex.youtube.video.reduce((acc, pattern) => {
        if (acc) return acc

        const match = this.url.match(pattern)
        return match ? match[1] : null
      }, null)

      return `https://www.youtube.com/embed/${code}`
    }
  }
}
</script>
