// Import the vue-router instance
import router from '@/router'

// Export the actions object
export default {
  /**
   * Update the query values in local state
   *
   * @param {Object} context
   * @param {Object} { key: String, value: String|Integer }
   */
  updateQuery({ commit }, { key, value }) {
    commit("UPDATE_QUERY", { key, value })
  },

  /**
   * Fetch items and store them locally to show in the table
   *
   * @param {Object} context
   * @returns {void}
   */
  async fetchItems({ commit, dispatch, getters }) {
    // Create the loader
    const loaderId = Symbol()
    dispatch("loaders/add", loaderId, { root: true })

    try {
      // Compute query parameters
      const queryParams = new window.URLSearchParams(Object.entries(getters.query).filter(([key, value]) => value))

      // Make the network request
      const response = await axios({
        url: "/api/campaign-tracking?" + queryParams
      })

      // otherwise push the results
      commit("UPDATE_RESPONSE", response.data)
    } catch (error) {
      // log using the helper function
      logger({ type: "Network Error", error })
    } finally {
      // close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Fetch the detailed object to be shown in the UI
   *
   * @param {Object} context
   * @param {String} uuid
   * @returns {void}
   */
  async fetchDetails({ commit, dispatch }, uuid) {
    // Create the loader
    const loaderId = Symbol()
    dispatch("loaders/add", loaderId, { root: true })

    try {
      const response = await axios({
        url: "/api/campaign-tracking/" + uuid
      })

      // Otherwise push the results
      commit("UPDATE_DETAILS", response.data)
    } catch (error) {
      // Log using the helper function
      logger({ type: "Network Error", error })

      // Redirect to 404 page
      router.replace("/404")
    } finally {
      // Close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Fetch the analytics object to be shown in the UI
   *
   * @param {Object} context
   * @param {String} uuid
   * @returns {void}
   */
  async fetchAnalytics({ commit, dispatch }, id) {
    // Create the loader
    const loaderId = Symbol()
    dispatch("loaders/add", loaderId, { root: true })

    try {
      const response = await axios({
        url: `/api/campaign-tracking/${id}/analytics/overview`
      })

      // Otherwise push the results
      commit("UPDATE_ANALYTICS", response.data)
    } catch (error) {
      // Log using the helper function
      logger({ type: "Network Error", error })

      // Redirect to 404 page
      router.replace("/404")
    } finally {
      // Close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Update the local value for the campaign model
   *
   * @param {Object} context
   * @param {Object} payload
   * @returns {void}
   */
  updateModel({ commit }, payload) {
    commit("UPDATE_MODEL", payload)
  },

  /**
   * Push or update the object to the list of members
   *
   * @param {Object} context
   * @param {Object} payload
   * @returns {void}
   */
  updateMember({ commit }, { id, member }) {
    commit("UPDATE_MEMBER", { id, member })
  },

  /**
   * Remove the object from the list of members
   *
   * @param {Object} context
   * @param {Object} payload
   * @returns {void}
   */
  removeMember({ commit }, { id, member }) {
    commit("REMOVE_MEMBER", { id, member })
  },

  /**
   * Update the cost value needed to unlock report
   *
   * @param {Object} context
   * @param {Object} payload
   * @returns {void}
   */
  decreaseCost({ commit }, id) {
    commit("DECREASE_COST", id)
  },

  /**
   * Update the cost value needed to unlock report
   *
   * @param {Object} context
   * @param {Object} payload
   * @returns {void}
   */
  updateCost({ commit }, { id, cost }) {
    commit("UPDATE_COST", { id, cost })
  },

  /**
   * Update the selected platform's value
   *
   * @param {Object} context
   * @param {String} payload
   */
  updateSelectedPlatform({ commit }, payload) {
    commit("UPDATE_SELECTED_PLATFORM", payload)
  },

  /**
   * Update the selected influencer's value
   *
   * @param {Object} context
   * @param {String} payload
   */
  updateSelectedInfluencer({ commit }, payload) {
    commit("UPDATE_SELECTED_INFLUENCER", payload)
  },

  /**
   * Update the query object for fetching influencers
   *
   * @param {Object} context
   * @param {Object} payload
   */
  updateInfluencersQuery({ commit }, payload) {
    commit("UPDATE_INFLUENCERS_QUERY", payload)
  },

  /**
   * Update the response object for fetching influencers
   *
   * @param {Object} context
   * @param {Object} payload
   */
  updateInfluencersResponse({ commit }, payload) {
    commit("UPDATE_INFLUENCERS_RESPONSE", payload)
  },

  /**
   * Update the query object for fetching posts in grid
   *
   * @param {Object} context
   * @param {Object} payload
   */
  updatePostsGridQuery({ commit }, payload) {
    commit("UPDATE_POSTS_GRID_QUERY", payload)
  },

  /**
   * Update the response object for fetching posts in grid
   *
   * @param {Object} context
   * @param {Object} payload
   */
  updatePostsGridResponse({ commit }, payload) {
    commit("UPDATE_POSTS_GRID_RESPONSE", payload)
  },

  /**
   * Update the query object for fetching posts in table
   *
   * @param {Object} context
   * @param {Object} payload
   */
  updatePostsTableQuery({ commit }, payload) {
    commit("UPDATE_POSTS_TABLE_QUERY", payload)
  },

  /**
   * Update the response object for fetching posts in table
   *
   * @param {Object} context
   * @param {Object} payload
   */
  updatePostsTableResponse({ commit }, payload) {
    commit("UPDATE_POSTS_TABLE_RESPONSE", payload)
  },

  /**
   * Update the query object for fetching stories in grid
   *
   * @param {Object} context
   * @param {Object} payload
   */
  updateStoriesGridQuery({ commit }, payload) {
    commit("UPDATE_STORIES_GRID_QUERY", payload)
  },

  /**
   * Update the response object for fetching stories in grid
   *
   * @param {Object} context
   * @param {Object} payload
   */
  updateStoriesGridResponse({ commit }, payload) {
    commit("UPDATE_STORIES_GRID_RESPONSE", payload)
  },

  /**
   * Update the query object for fetching stories in table
   *
   * @param {Object} context
   * @param {Object} payload
   */
  updateStoriesTableQuery({ commit }, payload) {
    commit("UPDATE_STORIES_TABLE_QUERY", payload)
  },

  /**
   * Update the response object for fetching stories in table
   *
   * @param {Object} context
   * @param {Object} payload
   */
  updateStoriesTableResponse({ commit }, payload) {
    commit("UPDATE_STORIES_TABLE_RESPONSE", payload)
  },

  /**
   * Push the invite form object into the list
   *
   * @param {Object} context
   * @param {Object} payload
   */
  addInviteForm({ commit}, payload) {
    commit("ADD_INVITE_FORM", payload)
  }
}
