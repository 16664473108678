<template>
  <div class="standard-page">
    <!-- The search form -->
    <v-card
      flat
      class="box-shadow-soft rounded "
    >
      <div class="d-flex flex-wrap align-center justify-space-between pr-4">
        <div>
          <v-card-title>
            Influencer Insight
          </v-card-title>

          <v-card-subtitle class="grey--text text--darken-2">
            Search an influencer profile to see their insights report
          </v-card-subtitle>
        </div>

        <div class="d-flex">
          <v-btn
            v-if="isBulkDownloadPossible"
            :to="{ name: 'InfluencerInsightsExport' }"
            class="shadow--button-primary ml-4 ml-sm-0"
            color="buttonPrimary"
            depressed
            large
          >
            Bulk Exports
          </v-btn>
        </div>
      </div>

      <v-card-text>
        <div class="d-flex justify-center mt-3 mt-sm-0">
          <!-- the select options for different platforms -->
          <platform-selector
            v-model="selectedInfluencerPlatform"
            class="rounded-r-0"
            outlined
          />

          <!-- show the input for searching terms -->
          <profile-selector
            @change="handleProfileChange"
            :platform="selectedInfluencerPlatform"
            :use-combobox="true"
            :hide-no-data="true"
            type="search"
            label="Search Profile"
            class="rounded-l-0"
            icon="arrow_forward"
            change-on-submit
            outlined
          />
        </div>
      </v-card-text>
    </v-card>

    <v-divider class="my-4 my-md-6 my-lg-8" />

    <!-- The header buttons -->
    <component
      :is="isDesktopDevice ? 'div' : 'v-slide-group'"
      :class="{ 'd-flex justify-end': isDesktopDevice }"
      class="mb-4 mb-md-6"
    >
      <!-- To download as PDFs -->
      <v-btn
        v-if="isBulkDownloadPDFsAllowed"
        depressed
        class="ml-3"
        color="primary"
        :disabled="isMakingRequest || selectedItems.length <= 1 || selectedItems.length > constants.model.influencerInsightExport.maximumItems"
        @click="handleDownloadClick('zip')"
      >
        <v-icon left> file_download </v-icon>

        PDFs
      </v-btn>

      <!-- To download as XLSX -->
      <v-btn
        v-if="isBulkDownloadXLSXAllowed"
        depressed
        class="ml-3"
        color="primary"
        :disabled="isMakingRequest || selectedItems.length <= 1 || selectedItems.length > constants.model.influencerInsightExport.maximumItems"
        @click="handleDownloadClick('sheet')"
      >
        <v-icon left> file_download </v-icon>

        XLSX
      </v-btn>

      <!-- To add to a group -->
      <v-btn
        v-if="isInfluencerGroupsAllowed"
        depressed
        class="ml-3"
        color="primary"
        :disabled="isMakingRequest || selectedItems.length <= 1"
        @click="showInfluencerGroupsFormDialog()"
      >
        <v-icon left> add </v-icon>

       Add to Group
      </v-btn>

      <!-- To add to a campaign -->
      <v-btn
        v-if="isCampaignTrackingAllowed"
        depressed
        class="ml-3"
        color="primary"
        :disabled="isMakingRequest || selectedItems.length <= 1"
        @click="showCampaignTrackingFormDialog()"
      >
        <v-icon left> add </v-icon>

        Add to Campaign
      </v-btn>
    </component>

    <v-card class="box-shadow-soft rounded rounded-bl-0 rounded-br-0 px-3 py-6">
      <v-row>
        <!-- Show the selectors -->
        <v-col
          cols="12"
          md="6"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="4"
            >
              <platform-selector
                v-model="selectedPlatform"
                label="Platform"
                small
                show-all
                outlined
                full-width
              />
            </v-col>
          </v-row>
        </v-col>

        <v-spacer />

        <!-- Show the input -->
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="searchQuery"
            label="Search"
            placeholder="Username, Account ID or Fullname"
            outlined
            dense
            hide-details
            clearable
            append-icon="search"
            @keypress.enter="currentPage = 1"
            @click:append="currentPage = 1"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>

    <!-- Show the results table -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableHeaders"
      :items="response.data"
      :server-items-length="response.total"
      :page="currentPage"
      :items-per-page="queryPerPage"
      @update:page="currentPage = $event"
      @update:items-per-page="queryPerPage = $event"
      show-select
      selectable-key="id"
      mobile-breakpoint="100"
      class="box-shadow-soft influencer-insight-history-data-table"
    >
      <!-- For Platform -->
      <template v-slot:item.platform="{ item }">
        <div class="d-flex">
          <v-img height="30" width="30" max-width="30" :src="platformIconMap[item.platform]" />
        </div>
      </template>

      <!-- For preview -->
      <template v-slot:item.preview="{ item }">
        <!-- Show the profile chip, if possible -->
        <profile-chip v-if="item.preview" :data="item.preview" :platform="item.platform" />

        <!-- Otherwise -->
        <span v-else>
          {{ item.fullname || item.username }}
        </span>
      </template>

      <!-- For created at -->
      <template v-slot:item.created_at="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              class="d-flex flex-column"
              v-bind="attrs"
              v-on="on"
            >
              <!-- Show the date -->
              <div class="white-space-nowrap">
                {{ formatRelativeDate(item.created_at, "MMMM D, YYYY") }}
              </div>

              <!-- If it's more than 7 days -->
              <div v-if="differenceFromNow(item.created_at) > 7">
                {{ formatRelativeDate(item.created_at, "h:mm A") }}
              </div>
            </div>
          </template>
          <span>
            {{ formatDate(item.created_at) }}
          </span>
        </v-tooltip>
      </template>

      <!-- To show a button for the graph -->
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-end align-center">
          <v-btn
            small
            depressed
            color="primary"
            @click="$router.push(computeRouteObject(item))"
          >
            View
            <v-icon small right> open_in_new </v-icon>
          </v-btn>

          <!-- Show menu options -->
          <v-menu
            transition="slide-y-transition"
            :close-on-content-click="true"
            offset-y
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-3"
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <!-- Show the menu options -->
            <v-list width="170" dense>
              <v-list-item
                @click="downloadReport(item, 'pdf')"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Download PDF
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                @click="downloadReport(item, 'xlsx')"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Download XLSX
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="isInfluencerGroupsAllowed"
                @click="showInfluencerGroupsFormDialog(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Add To Group
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="isCampaignTrackingAllowed"
                @click="showCampaignTrackingFormDialog(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Add To Campaign
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="isContentDiscoveryAllowed && ['instagram', 'tiktok'].includes(item.platform)"
                @click="showContentDiscoveryFormDialog(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Discover Content
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="isCompetitorCheckAllowed && ['instagram', 'tiktok'].includes(item.platform)"
                @click="showCompetitorCheckFormDialog(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Influencer Collab Check
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="copyReportLink(item)">
                <v-list-item-content>
                  <v-list-item-title>
                    Copy URL
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>

    <competitor-check-form
      v-model="shouldShowCompetitorCheckForm"
      :influencer="selectedInfluencer"
      source="insights-index"
    />

    <content-discovery-form
      v-model="shouldShowContentDiscoveryForm"
      :influencer="selectedInfluencer"
      source="insights-index"
    />

    <influencer-groups-search
      v-model="shouldShowInfluencerGroupsForm"
      :influencers="computedSelectedItems"
    />

    <campaign-tracking-search
      v-model="shouldShowCampaignTrackingForm"
      :influencers="computedSelectedItems"
    />

    <!-- Show the popup about the usage -->
    <v-dialog
      v-model="shouldShowInfoDialog"
      max-width="400"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <v-icon dark class="mr-3">
            lightbulb
          </v-icon>

          Info!
        </v-card-title>

        <!-- Show the image here -->
        <div class="mx-auto" style="max-width: 200px">
          <lottie-animation
            loop
            file="107650-creative-idea.json"
          />
        </div>

        <v-card-text>
          <p>
            1 credit unlocks 1 influencer profile insights.
          </p>
          <p>
            You won't be charged for reports that you've generated before, they don't expire.
          </p>
        </v-card-text>

        <v-card-actions>
          <!-- The checkmark -->
          <v-checkbox
            dense
            v-model="disableReminder"
            label="Don't remind again"
          />

          <v-spacer />

          <v-btn
            text
            color="primary"
            @click="handleInfoDialogClose"
          >
            Okay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import vuetify component
import { VSlideGroup } from 'vuetify/lib'

// Import helper functions
import platformRegex from "@/helpers/platformRegex"
import messageEvents from "@/helpers/messageEvents"
import { getClientID } from "@/helpers/clientHelper"

// Import child components
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ '@/blocks/common/ProfileChip')
const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")
const ProfileSelector = () => import(/* webpackChunkName: "profile-selector" */ "@/blocks/common/selectors/ProfileSelector.vue")
const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/blocks/common/selectors/PlatformSelector.vue")
const CompetitorCheckForm = () => import(/* webpackChunkName: "competitor-check-form-component" */ "@/components/competitor-check/Form.vue")
const ContentDiscoveryForm = () => import(/* webpackChunkName: "content-discovery-form-component" */ "@/components/content-discovery/Form.vue")
const InfluencerGroupsSearch = () => import(/* webpackChunkName: "influencer-groups-search" */ "@/components/crm/influencer-groups/Search.vue")
const CampaignTrackingSearch = () => import(/* webpackChunkName: "campaign-tracking-search" */ "@/components/crm/campaign-tracking/Search.vue")

// The subscriptionId for message events
const subscriptionId = Symbol("InfluencerInsight/History")

// Export this SFC
export default {
  // Name of this component
  name: "InfluencerInsightHistory",

  // Register the child components
  components: {
    VSlideGroup,

    ProfileChip,
    LottieAnimation,
    ProfileSelector,
    PlatformSelector,
    CompetitorCheckForm,
    ContentDiscoveryForm,
    InfluencerGroupsSearch,
    CampaignTrackingSearch
  },

  // Local data variables
  data: () => ({
    // Whether or not we're loading
    isMakingRequest: false,

    // The selected items to be deleted
    selectedItems: [],

    selectedInfluencer: null,
    shouldShowCompetitorCheckForm: false,
    shouldShowContentDiscoveryForm: false,
    shouldShowInfluencerGroupsForm: false,
    shouldShowCampaignTrackingForm: false,

    // Whether or not to show the info dialog
    shouldShowInfoDialog: false,
    disableReminder: false,

    // The triggerIDs to be shown toasts about
    triggerIDs: [],

    // The result table headers
    tableHeaders: [
      {
        text: "",
        value: "platform",
        sortable: false
      },
      {
        text: "Influencer",
        value: "preview",
        sortable: false
      },
      {
        text: "Created At",
        value: "created_at",
        sortable: false,
        align: "end"
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        align: "end"
      }
    ],
  }),

  // Define local computable properties
  computed: {
    /**
     * Get API response from the Vuex store
     *
     * @returns {Object}
     */
    response() {
      return this.$store.getters["influencerInsight/response"]
    },

    /**
     * Get the queries from Vuex store
     *
     * @returns {Object}
     */
    query() {
      return this.$store.getters["influencerInsight/query"]
    },

    /**
     * Get the current page number in pagination
     */
    currentPage: {
      get() {
        return this.query.page
      },

      set(value) {
        this.$store.dispatch("influencerInsight/updateQuery", {
          key: "page",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the number of items per page
     */
    queryPerPage: {
      get() {
        return this.query.perPage
      },

      set(value) {
        this.$store.dispatch("influencerInsight/updateQuery", {
          key: "perPage",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the selected createdBy
     */
    selectedCreatedBy: {
      get() {
        return this.query.createdBy
      },

      set(value) {
        this.$store.dispatch("influencerInsight/updateQuery", {
          key: "createdBy",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the selected status
     */
    selectedStatus: {
      get() {
        return this.query.status
      },

      set(value) {
        this.$store.dispatch("influencerInsight/updateQuery", {
          key: "status",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the selected platform
     */
    selectedPlatform: {
      get() {
        return this.query.platform
      },

      set(value) {
        this.$store.dispatch("influencerInsight/updateQuery", {
          key: "platform",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the search query
     */
    searchQuery: {
      get() {
        return this.query.search
      },

      set(value) {
        // If the value is same
        if (this.query.search === value) return

        this.$store.dispatch("influencerInsight/updateQuery", {
          key: "search",
          value
        })
      }
    },

    /**
     * The current selected platform
     *
     * @return {String}
     */
    selectedInfluencerPlatform: {
      get() {
        return this.$store.getters["influencerInsight/selectedPlatform"]
      },

      set(value) {
        this.$store.dispatch("influencerInsight/updateSelectedPlatform", value)
      }
    },

    /**
     * Whether or not the bulk PDFs is allowed
     *
     * @return {Boolean}
     */
    isBulkDownloadPDFsAllowed() {
      return this.$store.getters["auth/isServiceAllowed"](constants.model.user.allowedServices.actionBulkExportInfluencerInsightsPDF)
    },

    /**
     * Whether or not the bulk XLSX is allowed
     *
     * @return {Boolean}
     */
    isBulkDownloadXLSXAllowed() {
      return this.$store.getters["auth/isServiceAllowed"](constants.model.user.allowedServices.actionBulkExportInfluencerInsightsXLSX)
    },

    /**
     * Whether or not to show the bulk downloads link
     *
     * @return {Boolean}
     */
    isBulkDownloadPossible() {
      return this.isBulkDownloadPDFsAllowed || this.isBulkDownloadXLSXAllowed
    },

    /**
     * Whether or not to show the influencer groups form
     *
     * @return {Boolean}
     */
    isInfluencerGroupsAllowed() {
      return this.$store.getters['auth/isServiceAllowed'](constants.model.user.allowedServices.influencerGroups)
    },

    /**
     * Whether or not to show the campaign tracking form
     *
     * @return {Boolean}
     */
    isCampaignTrackingAllowed() {
      return this.$store.getters['auth/isServiceAllowed'](constants.model.user.allowedServices.campaignTracking)
    },

    /**
     * Whether or not to show the content discovery form
     *
     * @return {Boolean}
     */
    isContentDiscoveryAllowed() {
      return this.$store.getters['auth/isServiceAllowed'](constants.model.user.allowedServices.actionContentDiscovery)
    },

    /**
     * Whether or not to show the competitor check form
     *
     * @return {Boolean}
     */
    isCompetitorCheckAllowed() {
      return this.$store.getters['auth/isServiceAllowed'](constants.model.user.allowedServices.competitorCheck)
    },

    /**
     * Compute the selected items
     *
     * @return {Array}
     */
    computedSelectedItems() {
      // If an individual is selected
      if (this.selectedInfluencer) {
        return [this.selectedInfluencer]
      }

      // Otherwise
      return this.selectedItems
    },
  },

  // Method functions to be called from template and script
  methods: {
    /**
     * Fetch the items
     *
     * @returns {void}
     */
    async fetchItems() {
      // If we're already making a request
      if (this.isMakingRequest) return

      // Mark as loading
      this.isMakingRequest = true
      // Dispatch action to fetch items
      await this.$store.dispatch("influencerInsight/fetchItems")
      // Mark as not loading
      this.isMakingRequest = false
    },

    /**
     * Handle the download PDF action
     *
     * @param {Object} item
     * @param {String} type
     * @returns {void}
     */
    async downloadReport(item, type) {
      const triggerId = String(Date.now())
      this.triggerIDs.push(triggerId)

      await this.$store.dispatch("influencerInsight/downloadReport", {
        triggerId,
        platform: item.platform,
        username: item.username,
        accountId: item.account_id,
        reportId: item.id,
        type
      })
    },

    /**
     * Handle the message event
     *
     * @param {Object} event
     * @returns {void}
     */
    handleMessageEvent(event) {
      // Go through the possible values
      switch (event.key) {
        case "generate-influencer-insight-pdf-started":
          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "Generating your PDF ...", timeout: 4000 })

          break

        case "generate-influencer-insight-xlsx-started":
          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "Generating your XLSX ...", timeout: 4000 })

          break

        case "generate-influencer-insight-pdf-completed" || "generate-influencer-insight-xlsx-completed":
          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "Downloading your report" })

          break

        case "generate-influencer-insight-pdf-failed" || "generate-influencer-insight-xlsx-failed":
          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "Your download failed" })

          break
      }
    },

    /**
     * Copy the report link
     *
     * @return {void}
     */
    copyReportLink(item) {
      // Copy the link value
      const route = this.$router.resolve(this.computeRouteObject(item))

      navigator.clipboard.writeText(`${window.location.origin}${route.href}`)

      // Show a toast message
      this.$store.dispatch("toasts/add", { text: "URL copied to clipboard!" })
    },

    /**
     * Compute the vue-router relevant object
     *
     * @param {Object} item
     * @returns {Object}
     */
    computeRouteObject(item) {
      return {
        name: "InfluencerInsightReport",
        params: {
          platform: item.platform,
          username: item.username
        },
        query: {
          accountId: item.account_id,
          reportId: item.id
        }
      }
    },

    /**
     * Show the content discovery form dialog
     *
     * @param {Object} item
     * @returns {void}
     */
    showContentDiscoveryFormDialog(item) {
      this.selectedInfluencer = item

      // Show the dialog
      this.shouldShowContentDiscoveryForm = true
    },

    /**
     * Show the competitor check form dialog
     *
     * @param {Object|Null} item
     * @returns {void}
     */
    showCompetitorCheckFormDialog(item = null) {
      this.selectedInfluencer = item

      // Show the dialog
      this.shouldShowCompetitorCheckForm = true
    },

    /**
     * Show the influencer group form dialog
     *
     * @param {Object|Null} item
     * @returns {void}
     */
    showInfluencerGroupsFormDialog(item = null) {
      this.selectedInfluencer = item

      // Show the dialog
      this.shouldShowInfluencerGroupsForm = true
    },

    /**
     * Show the campaign tracking form dialog
     *
     * @param {Object|Null} item
     * @returns {void}
     */
    showCampaignTrackingFormDialog(item = null) {
      this.selectedInfluencer = item

      // Show the dialog
      this.shouldShowCampaignTrackingForm = true
    },

    /**
     * Handle the download click
     *
     * @param {String} type
     * @returns {void}
     */
    async handleDownloadClick(type) {
      // Show a loader
      const loaderId = Symbol("InfluencerInsight/History/handleDownloadClick")
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Map out the accounts
      const map = {}

      // Loop through each selected account
      for (const account of this.computedSelectedItems) {
        // If the account is not already in the map
        if (!map[account.platform]) {
          map[account.platform] = []
        }

        // Push the account to the map
        map[account.platform].push(account.username || account.account_id)
      }

      // Make the network request
      try {
        // Make the network request
        await axios({
          url: "/api/influencer-insight-exports/",
          method: "POST",
          data: {
            type: type,
            clientId: getClientID(),
            triggerId: String(Date.now()),
            accounts: map
          }
        })

        // Show a toast message
        this.$store.dispatch("toasts/add", { text: "Generating your export!" })
      }
      // Catch any errors
      catch (error) {
        // Show a toast message
        this.$store.dispatch("toasts/add", { text: "Failed to generate your export!" })

        logger({ type: "InfluencerInsight/History/handleDownloadClick", error })
      }
      // Nonetheless
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingRequest = false
      }
    },

    /**
     * Hide the dialog box
     *
     * @returns {void}
     */
    handleInfoDialogClose() {
      this.shouldShowInfoDialog = false

      // If the checkbox was marked, stop showing it from now on
      if (this.disableReminder) {
        // Update the user meta
        this.$store.dispatch("auth/updateMeta", {
          key: constants.model.user.metas.popup.info.influencerInsight,
          value: "hidden"
        })
      }
    },

    /**
     * Every time the user selects or submits the user input form, call this function
     *
     * @param {Object} selectedUser
     * @return {void}
     */
    handleProfileChange(selectedUser) {
      // If it's empty value
      if (!selectedUser) return

      // The username to redirect to
      let username = null
      let accountId = null

      // If the input is a string
      if (typeof selectedUser === "string") {
        // Get the value
        const string = selectedUser.trim()

        // If the string is an URL
        if (isURL(string)) {
          const match = string.match(platformRegex[this.selectedInfluencerPlatform])

          if (match !== null && match[1]) {
            username = match[1]
          }
        }
        // Otherwise
        else {
          username = string
        }
      }

      // Check if the value is an object
      else if (typeof selectedUser === "object" && selectedUser.value) {
        username = selectedUser.value
        accountId = selectedUser.idToQuery
      }

      // If username is not null
      if (username) {
        // Redirect to the report page
        this.$router.push({
          name: "InfluencerInsightReport",
          params: {
            platform: this.selectedInfluencerPlatform,
            username
          },
          query: {
            accountId
          }
        })
      }
    },
  },

  /**
   * As soon as the component is rendered
   *
   * @returns {void}
   */
  created() {
    // Fetch the items
    this.fetchItems()

    // Register a subscriber for messageEvents
    messageEvents.register({
      id: subscriptionId,
      module: "influencer-insight-report",
      type: "all",
      key: "all",
      validator: (event) => event.module === "influencer-insight-report" && event.localData.clientId === getClientID(),
      callback: this.handleMessageEvent
    })
  },

  /**
   * As soon as the component has been rendered
   *
   * @returns {void}
   */
  mounted() {
    // Check if we haven't clicked don't show again
    if (this.$store.getters["auth/getMetaValue"](constants.model.user.metas.popup.info.influencerInsight) === "should-show") {
      this.shouldShowInfoDialog = true
    }
  },

  /**
   * As soon as the component is destroyed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // Unregister the subscriber for messageEvents
    messageEvents.deregister(subscriptionId)
  }
}
</script>

<style lang="stylus" scoped>
.contain-select-width
  width 12em
</style>

<style lang="stylus">
.items-preview
  &__images
    position relative
    width 50px

    img
      width 40px
      height 40px
      border-radius 100%
      position absolute
      transform scale(0.95)
      border 2px solid white
      top -20px

      &:first-child
        top -22px
        left 10px
        z-index 2

  &__title
    margin-top -2px
    margin-bottom -6px

.influencer-insight-history-data-table thead tr th
  white-space nowrap !important
</style>
