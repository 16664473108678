<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-bind="attrs"
          v-on="on"
          :value="value"
          :label="label"
          prepend-inner-icon="color_lens"
          hide-details
          readonly
          outlined
          dense
        ></v-text-field>
      </template>

      <v-color-picker
        :value="value"
        @input="$emit('input', $event)"
        show-swatches
      ></v-color-picker>
    </v-menu>
  </div>
</template>

<script>
// Export the SFC
export default {
  // Name of the component
  name: 'ColorPicker',

  // Define incoming data
  props: {
    value: String,
    label: String
  }
}
</script>
